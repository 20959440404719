// How to update:
// Go to https://redash.cbhq.net/queries/19432
// Open network tab, find the request for the data usually something like 2217657.json
// Double click the request name to trigger a download for the json file
// Remove request metadata around products and paste instead of below array
// Don't forget to check the columns in case they change too

import uniqBy from 'lodash/uniqBy';

import { rawProducts } from ':data-marketplace/data/rawProducts';
import { categoryInfo } from ':data-marketplace/messages/categories';
import { packageInfo } from ':data-marketplace/messages/packages';
import type { Product } from ':data-marketplace/types/Product';
import { getTextSlug } from ':data-marketplace/utils/url';

const ungroupedProducts: Product[] = rawProducts.map((rawProduct) => {
  return {
    id: rawProduct.CATALOGUE_ID,
    categoryId: getTextSlug(rawProduct.CATEGORY),
    categoryName: rawProduct.CATEGORY,
    packageId: getTextSlug(rawProduct.PACKAGE),
    packageName: rawProduct.PACKAGE,
    productType: rawProduct.PRODUCT,
    assetId: getTextSlug(rawProduct.BASE),
    assetName: rawProduct.BASE,
    providerName: rawProduct.PROVIDER,
    sourceName: rawProduct.SOURCE,
    fromDateStr: rawProduct.COVERAGE_FROM,
  };
});

// Order all products descending by date so earliest data is at the top
const sortedUngrouped = ungroupedProducts.sort(
  (asc, desc) => new Date(asc.fromDateStr).getTime() - new Date(desc.fromDateStr).getTime(),
);

// Group items by Category > Package > Asset > Product type and unique to get the first item in each group
const duplicatePairsRemovedProducts = uniqBy(
  sortedUngrouped,
  // create a dictionary key
  (product) =>
    [product.categoryId, product.packageId, product.assetId, product.productType].join(''),
);

// Format the associated category and package information for each product
const formattedProducts = duplicatePairsRemovedProducts.map((product) => {
  const categoryProperties = categoryInfo[product.categoryId];
  const packageProperties = packageInfo[product.packageId];

  if (!packageProperties) {
    // eslint-disable-next-line no-console
    console.warn(`Missing text data for package: ${JSON.stringify(product.packageId)}`);
  }

  if (!categoryProperties) {
    // eslint-disable-next-line no-console
    console.warn(`Missing text data for category: ${JSON.stringify(product.categoryId)}`);
  }

  const formattedProduct = {
    ...product,
    categoryName: categoryProperties.name,
    categorySummary: categoryProperties.summary,
    categoryDescription: categoryProperties.description,
    categoryPriority: categoryProperties.priority,
    packageName: packageProperties.name,
    packageSummary: packageProperties.summary,
    packageDescription: packageProperties.description,
    packagePriority: packageProperties.priority,
  } as Product;

  return formattedProduct;
});

export { formattedProducts as products };
