import { PackageInfo } from ':data-marketplace/types/Package';

export const packageInfo: Record<string, PackageInfo> = {
  /* Exchange Data (available) */
  'trade-data': {
    priority: 0,
    name: 'Tick Level Trade',
    summary:
      'Tick level trade datasets captures all the trades executed for assets listed on the exchange at a micro second granularity',
    description:
      'Tick level trade datasets captures all the trades executed for assets listed on the exchange. Trade datasets are normalized and timestamped at a micro second level.',
  },
  'ohlcv-hourly': {
    priority: 1,
    name: 'Hourly OHLCV',
    summary:
      'Hourly OHLCV datasets offers price data summarized on an hourly basis. It contains OHLCV, trade count, and VWAP (including buy and sell side VWAP).',
    description:
      'Hourly OHLCV datasets offers price data summarized on an hourly basis. It contains OHLCV, trade count, and VWAP (including buy and sell side VWAP). All timestamps are expressed in UTC. Hourly interval start times are inclusive (e.g. 14:00) up to the hourly end times which are exclusive (e.g. 15:00).',
  },
  'ohlcv-daily': {
    priority: 2,
    name: 'Daily OHLCV',
    summary:
      'Daily OHLCV datasets offers price data summarized on an daily basis. It contains OHLCV, trade count, and VWAP (including buy and sell side VWAP).',
    description:
      'Daily OHLCV datasets offers price data summarized on an daily basis. It contains OHLCV, trade count, and VWAP (including buy and sell side VWAP). End of day period is based on 00:00 UTC. Daily interval starts at UtC 00:00 (inclusive) on the given date and ends at UTC 00:00 (exclusive) the following day.',
  },
  'last-price-daily': {
    priority: 3,
    name: 'Daily Prices (EOD)',
    summary:
      'Daily Prices datasets captures the last traded price per asset pair on the exchange, every day. [ UTC midnight and 4 PM ET ]',
    description:
      'Daily Prices datasets captures the last traded price per asset pair on the exchange, every day. [ UTC midnight and at 4 PM ET ]',
  },
  /* Exchange Data (upcoming) */
  'quote-data': {
    priority: 0,
    name: 'Quote Data (L1)',
    summary:
      'A top of book (L1) quote feed consisting of the best bid and ask recorded every time the top of the book has changed.',
    description:
      'A top of book (L1) quote feed consisting of the best bid and ask recorded every time the top of the book has changed.',
  },
  'l2-snapshot': {
    priority: 1,
    name: 'L2 Snapshot Top 25 Order Book',
    summary:
      'A Market by Price Order Book for the top 25 levels of depth. E.g. each row represents the top 25 levels from each side of the order book as recorded every time any of the tracked bids/asks of the top 25 levels changed.',
    description:
      'A Market by Price Order Book for the top 25 levels of depth. E.g. each row represents the top 25 levels from each side of the order book as recorded every time any of the tracked bids/asks of the top 25 levels changed. This allows customers to get visibility of the order book at any point in time without having to reconstruct the order book themselves (at the loss of full depth). This offering is effectively a pre-computed order book managed from processing all ticks to manage the complete order book where snapshots are provided for any price/qty changes on the top 25 levels of the book.',
  },
  'l2-tick-level': {
    priority: 3,
    name: 'L2 Tick-Level Order Book',
    summary:
      'A Market by Price Order Book with full depth of price levels. This is a full tick history allowing customers to reconstruct the whole order book. Snapshots are mixed at regular intervals to avoid customers having to reconstruct the order book from start of day.',
    description:
      'A Market by Price Order Book with full depth of price levels. This is a full tick history allowing customers to reconstruct the whole order book. Snapshots are mixed at regular intervals to avoid customers having to reconstruct the order book from start of day. Reconstruction of a full depth order book relies on a series of consecutive snapshot entries to baseline the order book. Delta entries (with snapshot flag as false) are used to incrementally manage the order book with add/update/remove actions. Orderbook actions are managed implicitly as follows in price-time priority order: Add - where price level does not exist for the given side. Update - where price level already exist for the given side. Remove - when quantity for price level is 0 for the given side',
  },
  'l3-tick-level': {
    priority: 4,
    name: 'L3 Order Book Data',
    summary:
      'A Market by Order level book with complete order activity to construct a full depth order book. Snapshots are mixed at regular intervals to avoid customers having to reconstruct the order book from start of day.',
    description:
      'A Market by Order level book with complete order activity to construct a full depth order book. Snapshots are mixed at regular intervals to avoid customers having to reconstruct the order book from start of day.',
  },
  'fixing-prices-daily': {
    priority: 8,
    name: 'Daily Fixing Prices',
    summary: 'End of day fixing prices at UTC midnight and 4pm ET (NYC market close).',
    description:
      'End of day fixing prices at UTC midnight and 4pm ET (NYC market close). The fixing price is an end of day (EOD) close price calculated using a methodology based on the VWAP of the trade price during an observation window near the close time (e.g. 4pm EST) if there are sufficient trades available during the observation window. In the event insufficent trades during observation window, a TWAP mid price is calculated from the orders during the same observation window. More precise details on the calculation methodology is documented elsewhere for reference. The end of day period is based on 00:00 UTC for CloseUTC. And the end of day period is 4pm ET (Eastern Time for NYC market close) for Close4pmET.',
  },
  /* Blockchain Data (available) */
  'exchange-inflows---outflows': {
    priority: 0,
    name: 'Exchange Inflows & Outflows',
    summary:
      'Exchange Inflows & Outflows datasets captures daily inflow & outflow of crypto assets across major crypto exchanges.',
    description:
      'Exchange Inflows & Outflows datasets offers insights into daily inflow and outflow of individual crypto assets across some of the major crypto exchanges.',
  },
  'miner-outflows': {
    priority: 1,
    name: 'Miner Outflows',
    summary:
      'MInor Outflows datasets captures daily outflow of individual crypto assets from major mining pools.',
    description:
      'Miner Outflows datasets offers insights into daily outflows of individual crypto assets from some of the major mining pools.',
  },
  hashrate: {
    priority: 2,
    name: 'Hashrate',
    summary:
      'Hashrate datasets offers average hashrate in terahashes/s for some of the major blockchains on a daily basis.',
    description:
      'Hashrate datasets offers average hashrate in terahashes/s for some of the major blockchains on a daily basis.',
  },
  'unique-addresses': {
    priority: 3,
    name: 'Active Addresses',
    summary: 'Daily unique active addresses on the blockchain.',
    description: 'Daily unique active addresses on the blockchain.',
  },
  'large-transactions': {
    priority: 4,
    name: 'Large Transactions',
    summary:
      'Large Transactions datasets offers top 20 transactions on BTC and ETH blockchains on a daily basis.',
    description:
      'Large Transactions datasets offers top 20 transactions on BTC and ETH blockchains on a daily basis; with the transaction amount in both native crypto currency and in USD.',
  },
  'blockchain-fees': {
    priority: 5,
    name: 'Blockchain Fees',
    summary:
      'Blockchain Fees datasets offers the total aggregated daily fees paid to miners for each of the major blockchains.',
    description:
      'Blockchain Fees datasets offers the total aggregated daily fees paid to miners for each of the major blockchains. Fees will be both in native currency and in USD.',
  },
  'transaction-count-and-volume': {
    priority: 6,
    name: 'Transaction Count and Volume',
    summary:
      'Transaction Count & Volume dataset offers the total no. of transactions on the blockchain and the associated volume.',
    description:
      'Transaction Count & Volume dataset offers the total number of transactions occurring on the blockchain and associated volume in both the asset unit and USD.',
  },
};
