export function getTextSlug(text: string): string {
  return text.replace(/[^a-z0-9]/gi, '-').toLowerCase();
}

export function getCategoryUrl(categoryId: string) {
  return `categories/${categoryId}`;
}

export function getPackageUrl(categoryId: string, packageId: string): string {
  return `${getCategoryUrl(categoryId)}/packages/${packageId}`;
}
