// How to update:
// Go to https://redash.cbhq.net/queries/19432
// Open network tab, find the request for the data usually something like 2217657.json
// Double click the request name to trigger a download for the json file
// Remove request metadata and columns
// Paste the array of products below to replace the array

// Base > asset
export type RawProduct = {
  CATEGORY: string;
  CATALOGUE_ID: string;
  PRODUCT: string;
  PACKAGE: string;
  QUOTE: string;
  SOURCE: string;
  BASE: string;
  COVERAGE_TO: string;
  PROVIDER: string;
  COVERAGE_FROM: string;
};

export const rawProducts: RawProduct[] = [
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '14fca0bd7f748cea1c3655ab6b72b7e9',
    PRODUCT: 'DOGE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b189f9273517e3115258d0f28769c8f0',
    PRODUCT: 'MLN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MLN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f7d1c9b88fe542c89fc47fe31099145',
    PRODUCT: 'AMP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0b5568907e364d19a29e6a72a79e1974',
    PRODUCT: 'DOGE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '251809dcbc85fd11c6c0071f9944e36a',
    PRODUCT: 'AMP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '28ec79c9984cb0082192f4d83c3544fa',
    PRODUCT: 'GTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08192d108176789d549b8432873a892e',
    PRODUCT: 'DOGE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1e74973716cc9bbdd92bf90cb58c7618',
    PRODUCT: 'DOGE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ab81f70db5014a165a6ac2a1b0c81126',
    PRODUCT: 'GTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8fa7ebbc05c1ac29ddc7afa2109f76f0',
    PRODUCT: 'MLN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MLN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'faf77c7e4519c84dc9f0bccbafff6c06',
    PRODUCT: 'DOGE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '46606d7154b43ad09a79637bf67d3baf',
    PRODUCT: 'AMP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47e3256d4917b97bd4fe11b73177df89',
    PRODUCT: 'GTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e1a9a202ab7e43571ca4f3a74aa8c5e2',
    PRODUCT: 'DDX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7d618bae2dc8223531c460d299b50bfd',
    PRODUCT: 'YFII Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFII',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '092d179dbc5b95529812f1bcc0457471',
    PRODUCT: 'RAD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3fef946436481dec1b8ee023a1bbda6',
    PRODUCT: 'RAD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72d0f6b13d0ba15a32047f9c9d098698',
    PRODUCT: 'RAD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '366b1021f568e8bd9e8a0c974b0c53c1',
    PRODUCT: 'RAD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da186a4bfd769def9500f665d79ce89f',
    PRODUCT: 'RAD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b62d09acc8690f57ffcef112c2fea41b',
    PRODUCT: 'RAD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e499cd69b40fa7f8888fe4660160aa4c',
    PRODUCT: 'DDX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d2293382131de7674d52a3cebf938f6',
    PRODUCT: 'RAD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe8f0b66d2b2ed3ea1cc0012816e4e7f',
    PRODUCT: 'RAD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bbf84be44f96c9b10209c4e89bb22875',
    PRODUCT: 'RAD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '078444707c95485528620509b324ffd6',
    PRODUCT: 'RAD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '43db2cf16e501664c052959f2366bac8',
    PRODUCT: 'YFII Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFII',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5e30a018ce770e288077000742b48f61',
    PRODUCT: 'RAD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb64ef4d82371281ad7cbaa2355a3879',
    PRODUCT: 'RAD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7bc0ac6628e97b392918370a6f20c4e2',
    PRODUCT: 'DDX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f5c7ef188d9c6ec97b960d45aa6881a6',
    PRODUCT: 'MIR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8dda6f3100326dd7ec3ab3c091bf36d3',
    PRODUCT: 'TRB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1272292a8e68ebc72337bda4faf9d7fc',
    PRODUCT: 'RLC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'efcf93853a19e7245b0900cfcec39cb3',
    PRODUCT: 'MIR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '19b573cabfae7952e7aa96dbe7d06554',
    PRODUCT: 'CTSI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f560e0641e8d739d2403f6be8f2b6b9',
    PRODUCT: 'FARM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b9f2f82be5ac6503ca5537dfe935787',
    PRODUCT: 'POLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8df532a641445e8b302151fe36dacc45',
    PRODUCT: 'FARM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3979f7f7372a4b882357901f48726db9',
    PRODUCT: 'POLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'acdfa3a2cbbc6f7df5d14dfe55238a51',
    PRODUCT: 'FARM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '26555d9d15232216b2b8595376f76f43',
    PRODUCT: 'POLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a3cfa128dbd0c5bc0d2b47812de49a26',
    PRODUCT: 'FARM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '32dcaf01333b40c9e393de8f05365f6f',
    PRODUCT: 'POLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '140668cea2bc34ce6d5f5a289158527d',
    PRODUCT: 'COVAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f13e2600b01594ee037512aed2e5eea1',
    PRODUCT: 'COVAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b1edcbb55d2297098ff924ce8074951',
    PRODUCT: 'IMX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3519d9ed139d2a8e435cefdffec658cd',
    PRODUCT: 'COVAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd9a9b2f2e5d77fe4500165d8105363c9',
    PRODUCT: 'COVAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '40fed20d2dcc55f8947e77e833cf752b',
    PRODUCT: 'COVAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f0767c450cb277dc3b3ec6755cce0615',
    PRODUCT: 'COVAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a0c58d2886abb7fb78e87564a8bd9ba6',
    PRODUCT: 'BLZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BLZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f529ae51ec1aa42dfc759d194233722',
    PRODUCT: 'COVAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd5b223d47c3fa39b806d981318d415a5',
    PRODUCT: 'COVAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'COVAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9f9c6e45ade0c77abdf54abe07120ee8',
    PRODUCT: 'IMX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6799edc520575f7979f73394637e8be',
    PRODUCT: 'NU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1fd8cef5c702468cc7245f3a623ca217',
    PRODUCT: 'NU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9b4eab65d000d368b0856f4a4fa4cef',
    PRODUCT: 'NU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f6754514a9e90d91c84cd59eb5252c1',
    PRODUCT: 'YFI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '935178559f3d23b4a3505e85c98c79aa',
    PRODUCT: 'ALGO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6403cab7dac2a07a17ba700d97f5ef51',
    PRODUCT: 'ZEC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e040a1fa73a774398c3cd6faa572d2fa',
    PRODUCT: 'YFI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ac45011505cfcaa0f538ffdb7787afa7',
    PRODUCT: 'ALGO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c436f3549075f5037b615fc3e249c46b',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '454ab0518f83679b105f0e8563dbbed9',
    PRODUCT: 'ZEC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '90d8f9ec752fe93992de83611e36b98d',
    PRODUCT: 'ALGO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '434e8470dba666b62534a00aa1c43247',
    PRODUCT: 'ZEC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9f6013bbef511c9fe8320335e51a9683',
    PRODUCT: 'NU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '63c54d958d529f4edf29181c0ee2e5ae',
    PRODUCT: 'NU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3275c62e028cc3a4030b8cbec1fca8d',
    PRODUCT: 'ALGO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b121e97622bd3fa8e98c58cd1d81e08',
    PRODUCT: 'ZEC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '730ed2d8b4c789152269c86d5df703a3',
    PRODUCT: 'VGX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f62650dacdf82a20cab72e3c06ca8db7',
    PRODUCT: 'VGX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3f44609264ba5f14a53546c2ebd0ddf6',
    PRODUCT: 'MUSD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2ad42ac49039ffe7795f42992e83eb0d',
    PRODUCT: 'VGX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '34df475bf3b3191e5ebde6c419324849',
    PRODUCT: 'VGX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '133ebd13264a70308ce48f53ce2efd18',
    PRODUCT: 'MUSD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '419afb86a7ae6d6dbc4295b0d1f441e6',
    PRODUCT: 'VGX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d1d3a403195ebcd549f27adb0134599',
    PRODUCT: 'VGX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ab1e68c9a7dedbd50d4427d89c4d2606',
    PRODUCT: 'VGX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f7b70825fddab9f1489ec75bda569bc2',
    PRODUCT: 'VGX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ba937b527faa6bed64b44ed849dee4a',
    PRODUCT: 'VGX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0760b6bceb6850b5de77134311c83e46',
    PRODUCT: 'MUSD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1348e7070ccfb11e6d1d83dc7d135545',
    PRODUCT: 'SUSHI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3d8152bc19da8d2fef0f6d9f1ed0ae71',
    PRODUCT: 'MATIC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2ff10d436b4f29d4ca59119eac54f7fa',
    PRODUCT: 'SUSHI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '97e26c1908d4572bfbf0963fb3964b90',
    PRODUCT: 'SKL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c7a288eed954165c9dbf035f9c0c027f',
    PRODUCT: 'SUSHI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a641e2a86c36b4228a091874be9118b6',
    PRODUCT: 'MATIC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8649d259e11512a448046997ff5910d9',
    PRODUCT: 'SUSHI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b5ca4201b657e057ba5940220932d93',
    PRODUCT: 'MATIC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5e421823d3b7edba78be59f2422fe4d6',
    PRODUCT: 'AGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5dd732c0a8cfbc66c9f2e612d8c63267',
    PRODUCT: 'AVAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5bfb265cb5cc4f71f1e41e6f33cba6f3',
    PRODUCT: 'AVAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3f8ee53575364d762a2489abb53ec101',
    PRODUCT: 'AVAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f30d11d09bf207e32807fc1501f6dad',
    PRODUCT: 'AGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c2552d912a5fe377c5f2562e0d337987',
    PRODUCT: 'AGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8b8f128f58e638766b9d6852cf7c0ee1',
    PRODUCT: 'AVAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd8658e205c3e651f77a4e7946e21cee3',
    PRODUCT: 'AVAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aa5810bf423935a9daea32fc94b2f01e',
    PRODUCT: 'AVAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b3a7a6722338be7cc59c09e5be1208b',
    PRODUCT: 'AVAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '80444f001f2c2934139580de40e754dd',
    PRODUCT: 'AVAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a44398dacdf0f3f7e4f99f041f613134',
    PRODUCT: 'AVAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f95ce3fe4e12a1592b4a536782c8fa0b',
    PRODUCT: 'AVAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9f2dd17d60051249a9cb423fb0c43cc',
    PRODUCT: 'AVAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e652419773fc63e939543451ebb21004',
    PRODUCT: 'AVAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82b6a6d42141e2b6ba04f174675bbd66',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '17a6ca5106f6ffb5eaded26c2e74358f',
    PRODUCT: 'UNI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a1a33309c2c8fff99cbdf37c1d3df683',
    PRODUCT: 'YFI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2075a8fdfc47b51454ccd79852e6d8f5',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd53ed8ab5d4b074213ce72a28c3670aa',
    PRODUCT: 'UNI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e633387150222a6acb62052bb10b6fbf',
    PRODUCT: 'UNI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0b781fd8a46a27c2200dec3c727a9721',
    PRODUCT: 'YFI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3fae303ae6724bc72bd2159b0acc128c',
    PRODUCT: '1INCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e39093816c9220dab4d34d00d0459cc9',
    PRODUCT: '1INCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a32297a64673a9609135a35cdcd3ca34',
    PRODUCT: '1INCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1fb6ad665040b32d59b8be775a62d30c',
    PRODUCT: 'ENJ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c6275054d8dd475661419edbcb8a1f82',
    PRODUCT: 'MANA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'edf454bb93a0b02893393651100d4c9a',
    PRODUCT: 'MANA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '920b4df97c03bf387c421c10698e6d76',
    PRODUCT: 'MANA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c79b6ffac19ada63d8abbff7268ee24',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8999ffd2fa6d62785bff4a7cab1f5203',
    PRODUCT: 'NKN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b368bd37bb20a3f5b27ea36673696ef3',
    PRODUCT: 'ENJ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29917ebc4d630e9a8b77397bf7e7981d',
    PRODUCT: 'OGN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8ec30d6a74b8747faa54a5562dd29cdd',
    PRODUCT: 'MANA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '39c7fbc09048f20caba4b75da5b82063',
    PRODUCT: 'MANA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a47e89ec5a868ef8bd8543f09c4645c',
    PRODUCT: 'USDC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3c9e1890f132d79464cb95da5f478a67',
    PRODUCT: 'MANA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61e2da22f70f6ca1e3db22e63f8bf923',
    PRODUCT: 'BAT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7367a39f14b3d1e2419d5f1bf51f295',
    PRODUCT: 'MANA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd0bef5e811d105f653cd9465fe9de017',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9c445d1a257e3bb925d3c1dc2aa4dc3',
    PRODUCT: '1INCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '928b2cd7c597742bf3192920ae4a66db',
    PRODUCT: 'NKN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '71a7a3ebed550d964f658acc723da9e6',
    PRODUCT: '1INCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a9d196f0278d4cca09eb4bf2e44b7bc3',
    PRODUCT: 'ENJ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f78bd810bea57b9593ee1d118ed0f93',
    PRODUCT: 'USDC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c7cf4941cffa25481d99fa3ee613ce6b',
    PRODUCT: 'MANA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8f1fddab9f6c229b7ab494521698aa76',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '626f2082bdd42554b5ee1936e0ab8151',
    PRODUCT: 'BAT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '57283c125d5df1e31adf0e17d61f8648',
    PRODUCT: 'ENJ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '00d543f2593940d915c9c36f4ff94857',
    PRODUCT: 'ENJ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f327702e022f509b4dd0b0a772b091e1',
    PRODUCT: '1INCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a2d6f768be7c9eea4fb8e138a8b684a',
    PRODUCT: 'USDC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '38a7fe7bee0b79db5a9c85df31cc4917',
    PRODUCT: 'USDC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a610830b94bcfa636c435f74bacfe4d6',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '629ac5234f2ad1bb29ec88c987570caf',
    PRODUCT: 'BAT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a0de6793a7830b6804dfdc850e943e5e',
    PRODUCT: 'BAT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0276b0933fbec0df5bf969688998033',
    PRODUCT: 'MANA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '089c07147ef50305eb3aff53468e2675',
    PRODUCT: 'ZRX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50f233722e38f69f7cd5f1e1a5ac08e3',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b99ec0000bf355a4208541374770cd9',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8b7d6c0f24fdd7ad1e95c35a97f903c4',
    PRODUCT: 'BAT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd2d4015cf6af90e8baec5d3f54dcbe66',
    PRODUCT: 'ZRX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dde2cb29dded4952abcadd0cf0feaf63',
    PRODUCT: 'ZRX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '36b41e444a70a5ce061e484b0ac90723',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b05c2ef532ee92c833fbcc8a204521c4',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9edb65ba61e2d2fc2f534a6cf5de1f49',
    PRODUCT: 'BAT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84ecf06d35641b130e12ccb32280790e',
    PRODUCT: 'ZEC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd1a20fdf5fddb03857f7d1d33aa20e32',
    PRODUCT: 'ZRX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f26e325bad5c3c9e69fe456eb612d257',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '612c99e2ffbb33a8fa8a0e7bc6e5ff65',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cd57d3934234d082eaaffeef8b343b47',
    PRODUCT: 'BAT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '63baeb6b57a226709716292f220be182',
    PRODUCT: 'ZEC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f08cd740273f91f02bf8c70e1a9f8b30',
    PRODUCT: 'ZRX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '233c0578fa106aa29584b3869bacc6bf',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61f1acfb7e76bb9fa2aa8c9ea647fc25',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'feea40819e1482f2dcbfcd5c5f3fbab4',
    PRODUCT: 'BAT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a71c95fbe33f55efc09488ba50fbe40b',
    PRODUCT: 'ZEC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6804ba831231d243eb44e1a281a28e1a',
    PRODUCT: 'STORJ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d3a27f18ce51f5872bbc77d6f110d9b',
    PRODUCT: 'ANKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '06b820e9c7b52a4cc552f4ce78774c94',
    PRODUCT: 'ANKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b11213d2faae740a1773f5c39bdef1c',
    PRODUCT: 'CRV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc98311a199b9a40f533dd5de693adfa',
    PRODUCT: 'ANKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '267cc6c61adb19e949c81259cad823a0',
    PRODUCT: 'ANKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd9383e15728c2365330b61449f9e4740',
    PRODUCT: 'CRV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5d546beceed9a2cff45a9c36ead4968',
    PRODUCT: 'ANKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3efb9091009e2b8bb825e0281b589516',
    PRODUCT: 'STORJ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0d1c37e1ff34a3c8d43db5cd71c7d39f',
    PRODUCT: 'ANKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c00b955b13983abdfe034a3bf89b591b',
    PRODUCT: 'STORJ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '252c22ca54fb12ecee9a4e2752acbd97',
    PRODUCT: 'CRV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e4e815587c87b18df293b7ff005b8d4',
    PRODUCT: 'CRV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '66d60eebd2b3badababe53035184cb31',
    PRODUCT: 'ANKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '821b99bf503f2777d076b6acdb6876dc',
    PRODUCT: 'ANKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91c900ceb20280f98e7713e53f8c7f56',
    PRODUCT: 'CRV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1ff5d881ed935345a06dd4d5e4f9e5d1',
    PRODUCT: 'LTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a7e11fe344e47692e185b36d016e7648',
    PRODUCT: 'LTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '64a6ad07459a9321fdff7074a6e87dcf',
    PRODUCT: 'LTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '45aa038b3b1fbf17d107dbdceedc39da',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '095381a34562e07f5aaa126d328d29b8',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cad9d1dbc4bc010599e6e7024292df49',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4c9e7d09bd14587805fec87220adc4e7',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29499aad0ee2b4279c1415a45aced4f8',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '37963c2cdeb1bedcfadebc86eb05ced7',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5ce0f06aa8d07f7ea6cfe8058f979fc1',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '70069a0469a7e95f0d4409c5b1d77a47',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-05-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '87737a7a64594ed093219d89e8527945',
    PRODUCT: 'LTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '90693468fdbab647b207e6299261b01a',
    PRODUCT: 'LTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88c225cca3c275bb7cdf8d9aaa5c999e',
    PRODUCT: 'GNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'GNT',
    COVERAGE_TO: '2022-03-07',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4011a479c6032c8c650d428437e6c5cc',
    PRODUCT: 'DAI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dd5cda90a4ba93766fde05e76b2a4d76',
    PRODUCT: 'CVC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41abf3173c2252290c6c9a08523e5343',
    PRODUCT: 'LOOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb88f109f1409a4ac75d838ddf97f276',
    PRODUCT: 'GNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'GNT',
    COVERAGE_TO: '2022-03-07',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04d560979c9ee789a4fec1e946e30ab5',
    PRODUCT: 'DAI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f1fa779d0501ca4aca6feceedd1dea6f',
    PRODUCT: 'LOOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ca753675d6f0ebfd91fd217c5332890b',
    PRODUCT: 'DNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5172c0bc4fbc520fb1b569934ae0a828',
    PRODUCT: 'CVC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3254834ecaca4b792611544450c33536',
    PRODUCT: 'GNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'GNT',
    COVERAGE_TO: '2022-03-07',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e66adf61dbc0f16c83f1f067a3a9178',
    PRODUCT: 'DAI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4c80e7808bc7185c16aa3bc110c7be77',
    PRODUCT: 'GNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'GNT',
    COVERAGE_TO: '2022-03-07',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '10bbc48ac7867e9afd2538f6cf15fe39',
    PRODUCT: 'DAI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb02428f876731b1e3dc634d3b830169',
    PRODUCT: 'CHZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f745b66572a8a488d5f20887c83c54a9',
    PRODUCT: 'CHZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4cd6577c70408c8886fd451fb8666f43',
    PRODUCT: 'CHZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7addcf428ae3d3a97267361bb9e077c2',
    PRODUCT: 'CHZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7142365d41d3a4a40ce35310e7bf2dc',
    PRODUCT: 'CHZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cfc11f46e874fcc0a459bdd269854e2b',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3847827c6b994e2143bc46e468ba54fb',
    PRODUCT: 'CHZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1a8d868af56725ba5e8593fd01d3125e',
    PRODUCT: 'CHZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd54b866276debaad5a8d78c62d2f6994',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9829ed7a9e0dda39caa8c60d3d7d17d',
    PRODUCT: 'CHZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f10ca764629a1ad33ba7bd0dd173cdad',
    PRODUCT: 'CHZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91e31cd265d19742cea0e5c4b0c7aa3a',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd90a570b59d8f9c961b0532879f29378',
    PRODUCT: 'CHZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5edcdb22ccf714f2d49b16701d147bb1',
    PRODUCT: 'BUSD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9a0a1634bba71c65de0d50c17a48ab67',
    PRODUCT: 'GMT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc00d06b143fcba769791033caa2ee80',
    PRODUCT: 'GMT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e07586238c84e37823a13ae4f1b4727',
    PRODUCT: 'GST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d464276150acb0388f7f26a4a39968b',
    PRODUCT: 'BUSD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8aef9ca76ace3a42dd97b2ed9b290345',
    PRODUCT: 'BUSD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-04-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '97f119f9d792c280c3473509183963a6',
    PRODUCT: 'GST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7e24899bc5dd7c7abace4b29b8d8e7ee',
    PRODUCT: 'GMT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4d225664a2c9e372da34f64303066dde',
    PRODUCT: 'BUSD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-04-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '527e6d8d23cfc5345a3504b63133e253',
    PRODUCT: 'GST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '21ae5e6eb21ba194337d00bf4e2d8599',
    PRODUCT: 'GMT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3c5a909546327920125d553423a4acb0',
    PRODUCT: 'BUSD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e8cb5c6deb3661cb5491077536b7755d',
    PRODUCT: 'GST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5dce0c48a870e51cc49611c06ef3aec9',
    PRODUCT: 'GMT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '408e9aca892a8c2d80acdfe64d0dd396',
    PRODUCT: 'GMT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3b7c6c1feccbd81a4ec6f62977400b1',
    PRODUCT: 'SHPING Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '211a0e5c500a60d6c285cd638c926971',
    PRODUCT: 'SHPING OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '80e5444053861328990e735ca5cdac02',
    PRODUCT: 'STX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11d9e307fe6c4c2667535d24aef8e277',
    PRODUCT: 'UNFI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88a940c8c0b9f31287d54f56e8e85f46',
    PRODUCT: 'STX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd43dfb4e4934f28b961a17b54358f405',
    PRODUCT: 'SHPING OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb774060b1dce7ccf0e12deb559d072d',
    PRODUCT: 'DIA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '80be045ab98f2774a72351816230738f',
    PRODUCT: 'ALGO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe8c0ae95bf3be1fdb0df99bf0468f90',
    PRODUCT: 'XRP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9d59af6ac642d527e80847e04990e2bf',
    PRODUCT: 'XTZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ffd52e3665360484709d73f21756d3fb',
    PRODUCT: 'XTZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ade8432a1b1aad89751aca3674bf668',
    PRODUCT: 'XRP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7bf5bedcbf20b3211a39ed4326b569ba',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d74739ac5e27cc5da9132f3d6cf12f4',
    PRODUCT: 'ALGO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8b087f03a35b3173cd1b83522ad7deff',
    PRODUCT: 'XTZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0a1e613da300125e9cbf3176d10d4c55',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0683747c8041bd93f64ea3e6f3793fec',
    PRODUCT: 'XTZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9cf9e28e709b0f1701b0ec4f85ec6cf',
    PRODUCT: 'XTZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2bacc23e2f24de2da640c0db146ef86c',
    PRODUCT: 'XRP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a162563e2e51c216e2d20cb94306b206',
    PRODUCT: 'XRP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bad818f0631bc8b2fed67e65e1fef8a3',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd520793404a50b78742bdca4df4d1617',
    PRODUCT: 'NKN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'df30b6c702f9182072be1d86f7b83b16',
    PRODUCT: 'BADGER Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d0d9ce82c1345ea49b8aee3cc00bf91',
    PRODUCT: 'BADGER Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0041f15d8d50fd2817394dcc02a92c31',
    PRODUCT: 'NKN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b4ec3d6d92c9cad8b22566d64f84415',
    PRODUCT: 'NKN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2a29e0ff64fed4cdaa3cf3b25932ac87',
    PRODUCT: 'BADGER OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '106115a9e39b793769a73a131d6ffb70',
    PRODUCT: 'BADGER OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8510938a7600ddc39cb2dc26b2a33956',
    PRODUCT: 'RARI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RARI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c100f7dc052035b03765178856eda97',
    PRODUCT: 'BADGER OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd6f307f7e7bf2935d76983fca8921347',
    PRODUCT: 'NKN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5991c2c6a8ff9247884689fb0bfea4d1',
    PRODUCT: 'BADGER OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '27909ed6939338d5b62576b73e9f8853',
    PRODUCT: 'NKN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'caec0e1b30ba054a4fe9a85f601d6fa6',
    PRODUCT: 'NKN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b21650b1ddb8895623abfe8857a7de2b',
    PRODUCT: 'BADGER Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '907b470607a2d899045921d7bfba0145',
    PRODUCT: 'BADGER Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f981e06fa3676666d46b0974e1156c1',
    PRODUCT: 'RARI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RARI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '599b4d8373a4034d0b6a5e27fa19ee71',
    PRODUCT: 'FX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c8955f26265d459e194d2fd9e7dc3df5',
    PRODUCT: 'JASMY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'JASMY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dac3a1561bf62009bc3fcba07d9e9bee',
    PRODUCT: 'WCFG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c4a1bc655a696dbd6e4eae740354277d',
    PRODUCT: 'WCFG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '218d674398ef8b7185297bad39fb9183',
    PRODUCT: 'WCFG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f6dc51fb396874726db4176da40a488',
    PRODUCT: 'FX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '894553fe7bcd5deab1ed190727d50aa2',
    PRODUCT: 'WCFG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cdc312613851a64b72d167f43e7ba4cc',
    PRODUCT: 'WCFG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2137179dda751045d32a2d90908cf82e',
    PRODUCT: 'WCFG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef34d0af392c1332592d1c233650fc81',
    PRODUCT: 'WCFG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7beb198b56a1d95020408f0576edcee4',
    PRODUCT: 'WCFG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '121fcae62dd68b4c9b07a52ee894e4f5',
    PRODUCT: 'WCFG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c022a2e1f03f4be7e80bbd37447edbdd',
    PRODUCT: 'WCFG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e6cdcfa17ccced241930ee1654521b88',
    PRODUCT: 'FX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a369016590e0c5d19dc0cb0b4856486d',
    PRODUCT: 'WCFG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b47b669d61f7f516de7fcd7c0eb28b2b',
    PRODUCT: 'WCFG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ddb316d8286b3f9bc7a03b04c03b1b1',
    PRODUCT: 'JASMY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'JASMY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0923ee288ee414fe5060dd8cf0770f85',
    PRODUCT: 'WCFG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8fcbd6b3c859467a547b337e61eb25aa',
    PRODUCT: 'WCFG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5803ddb7376878acc5ba01ee497fd674',
    PRODUCT: 'FX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c731800658c746e4adc566883a276b66',
    PRODUCT: 'XRP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1327065cffccd6e7f6fd21354021b3e0',
    PRODUCT: 'XRP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04f2f0ed03f83a121c01422c25c313ea',
    PRODUCT: 'XRP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '993746d83503ca91ffddb3e1213a2e7f',
    PRODUCT: 'XLM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '35aa63713ee00637707b347f6feacd88',
    PRODUCT: 'XLM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b5aea873e1472496be3b4c4682978058',
    PRODUCT: 'XRP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8bd8d91e471a171fee95197259f0a2c9',
    PRODUCT: 'XRP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6e4db76c4ca7a902e8770c9907fee3da',
    PRODUCT: 'XRP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3618c1b88263862492af129cacfc1278',
    PRODUCT: 'XLM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '20079c87bbe57a109be004da51e2a3d6',
    PRODUCT: 'XLM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ee9979e5408c18217e79f2f381a3dd3',
    PRODUCT: 'XLM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '930deac72ec51c6fbb3d28bf72a35697',
    PRODUCT: 'XRP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3d1c7942016634087e218167cbcbd6fd',
    PRODUCT: 'XRP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b837bddf23971e50afe3e8e6618a457',
    PRODUCT: 'XRP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ff66bd3eb863ca0ac058ff3eae7b12e0',
    PRODUCT: 'XLM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a7c59cb57cebe9ddae21352d5a4132a',
    PRODUCT: 'XRP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50aa51281c1547c2a04e6fb1154b0eb8',
    PRODUCT: 'XRP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '569a514abb1cc7e5e35df7a108fc4c33',
    PRODUCT: 'XRP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XRP',
    COVERAGE_TO: '2021-01-19',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-02-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd8a0065e3fdfd36ea4706c42b8dad241',
    PRODUCT: 'XLM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '48f821bb61abb0c2c05339f161c6810c',
    PRODUCT: 'XLM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3965a1b49a5789e60e871e13a3f79482',
    PRODUCT: 'NCT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e94dd147b26f82cf48d8625675f3262c',
    PRODUCT: 'NCT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81bf7bd8766875cf44f1b14291ce1640',
    PRODUCT: 'PRO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b67cdbb8a0a1fbbf58cbea0b8cb350f3',
    PRODUCT: 'LQTY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '600c97f1c30d8442ff4654b0e22a2ed6',
    PRODUCT: 'LQTY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c76d30222ec59a84d18f3aad2460c4f7',
    PRODUCT: 'PRO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a3abf3dab08aa4dbb5bf08ae0769aacd',
    PRODUCT: 'NCT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ec26da9745309553bf964f73ae800af0',
    PRODUCT: 'AIOZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fdea30f4bd570a5579f70f2aa3c44e3c',
    PRODUCT: 'AERGO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AERGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6fe69c915cf03ff99f55799239b9d091',
    PRODUCT: 'SNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f5fcd9d89b3fab635ff6b260545180a',
    PRODUCT: 'AIOZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f7d3c7decd0717ffc5e8d127d9f541c7',
    PRODUCT: 'AIOZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'efa21bf10b78a6c97b8b9d5edf401352',
    PRODUCT: 'ERN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd86468bad47b3184f908ab5f2736e68d',
    PRODUCT: 'SNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd818c98a4bfc11965844bda23545cdfc',
    PRODUCT: 'ERN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ab177db4da64fda6d8216e190ca3f9a0',
    PRODUCT: 'ERN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7cac00ad1af1e21e5a513d062ea7789e',
    PRODUCT: 'HIGH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'HIGH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '70fe5fcedb88f4e6ce9674464429001b',
    PRODUCT: 'SNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9c397d7e39bc1830ed49cb6232fe20b',
    PRODUCT: 'AIOZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '787c3efd0a84a995e53351e961c84a90',
    PRODUCT: 'AIOZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '17ac50e24f7f580140bc52cd95f32bc2',
    PRODUCT: 'ERN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47a09c93228eb93d7d77ae7112e1138f',
    PRODUCT: 'ERN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c86759d77bf5777637adb354ff0398a1',
    PRODUCT: 'HIGH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'HIGH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4d7acfbae341a34c1d22c6cb69867d8f',
    PRODUCT: 'ALGO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b56cb1cc8363a924cfc964df3a553183',
    PRODUCT: 'DASH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8c6295632de14bb1ee7be82c6845d39f',
    PRODUCT: 'DASH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '51b988060cd3edbdcfb42e3d16f7ee8f',
    PRODUCT: 'OXT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OXT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd547a68b81851936de60e185a393f0f4',
    PRODUCT: 'ALGO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f61612c759d268b3d41fbda1083eb273',
    PRODUCT: 'DASH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '76baf4559fa24f666f85d90ae6ca1706',
    PRODUCT: 'DASH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1c5d5b3d9c2b87b1cafa54f6bea261ee',
    PRODUCT: 'OXT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OXT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '57d079810c8c656df311bf109920cb1c',
    PRODUCT: 'ALGO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0a027a750f73c1ded7295d633428b319',
    PRODUCT: 'DASH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a546d20109171ac3255110346fa965f1',
    PRODUCT: 'DASH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef9ef270c9eedcef507fa833ef8cf5a3',
    PRODUCT: 'OXT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OXT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c82beca9e0ec2bdddcc0f38855fdeb6f',
    PRODUCT: 'ALGO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a25db04c20e0d7b22a7a6d63e5a81eaa',
    PRODUCT: 'DASH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc388254a3e4f45447745ccdfaf2a2e2',
    PRODUCT: 'DASH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DASH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b547e24cdea391ec1522354c050e262',
    PRODUCT: 'OXT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OXT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c61f7d2eee96345d2614f7f0288aecd0',
    PRODUCT: 'NKN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cc1652be17ed90307c6281fe39da8761',
    PRODUCT: '1INCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee5d070b869cd9fa53d2ca68dd7e404f',
    PRODUCT: 'NKN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9ad4319c7a29ba1c0a206235b34e970',
    PRODUCT: 'ENJ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3fa0e35e85fd7495c6b814e7077e638e',
    PRODUCT: 'OGN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d1931ac9fa2c627281c47b4157eb388',
    PRODUCT: 'OGN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96c5ba9ecd6965297f4ac6a18ec2f082',
    PRODUCT: 'NKN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb9453ba515f8c7e18a7929a678bc691',
    PRODUCT: 'OGN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '06fcb7ae1c14e3743d3e68af66de0297',
    PRODUCT: '1INCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5234e72d0fb27fe94f9dbc0b12a2fb47',
    PRODUCT: 'ENJ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a996a8816a14d8890e948c136057ba4',
    PRODUCT: '1INCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8f56f4dcdc61b82c5e32e6449fec4302',
    PRODUCT: '1INCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b14c1bd8d7eb6aaf7e18b06528d30cfe',
    PRODUCT: 'NKN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e76f71f7b754d2cc9842a7b4f23933c',
    PRODUCT: 'ENJ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5389a62f78c099a0b096e05237c238a2',
    PRODUCT: '1INCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '142588f526b01048b4d19c2732d55abf',
    PRODUCT: 'OGN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '02f7ad627636d6ba70d9fb58ac17c9cc',
    PRODUCT: 'OGN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96110503f33eae72f594b979b84b6909',
    PRODUCT: '1INCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd3b310d2a1c8ea11a49f894a1705c4f6',
    PRODUCT: 'NKN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6eb28e1853b7ed344ba66353617e3205',
    PRODUCT: '1INCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4138426d211e28ddb747f534e753b53e',
    PRODUCT: 'OGN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96e8c0149d2e3e642828b3b2b479c78b',
    PRODUCT: '1INCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8be146e52eca9deafc11a9412fc27b2e',
    PRODUCT: 'NKN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '908f6ea9bfccc474ecefbfdfb6ad7c14',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eae4efd42b10a9e8c1f63413cd2eed16',
    PRODUCT: 'KRL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb31be9de9397a14d6641d1547bbec8d',
    PRODUCT: 'UNI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e758579e88b84bac3b630960407517eb',
    PRODUCT: 'SHIB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5eb321b829ce1c7d7f0b2ed70ba8061e',
    PRODUCT: 'UNI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da10170d5a9f1326b74dbc21d1f23677',
    PRODUCT: 'TRU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ce71ab5cb45b677499035aa01d80006',
    PRODUCT: 'WLUNA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f4dadbafd088891a4a4562c38291c56',
    PRODUCT: 'WLUNA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3bc5b433af5ca21c93949efac859e9f9',
    PRODUCT: 'REQ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dfbd10a2ac2cd9b0a1768481549f38ad',
    PRODUCT: 'REQ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b252bd8223cc7c875f8081365284a859',
    PRODUCT: 'WLUNA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3a0ec2c964d4c5132b5e624f8179872',
    PRODUCT: 'AXS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b98a41ed94022a1eb632983cfd7ad44',
    PRODUCT: 'REQ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41d7f3b67209115c931980d84dbd36bd',
    PRODUCT: 'FORTH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '398cf6819bd2ec48ae457b882f994ec0',
    PRODUCT: 'FORTH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c3172679f3cbbc28daf3f58b7526c10e',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c88d51ced73e7c23f53cfcef063f0901',
    PRODUCT: 'USDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb747c9ee124af68a81b281f8e2db3c2',
    PRODUCT: 'USDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9dba0162c64a62a62ef877d226aa5a05',
    PRODUCT: 'USDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b3858c3917ca5fd3d037cf6096bf6f74',
    PRODUCT: 'FORTH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cf6631b757607163af2a4b0a43b05722',
    PRODUCT: 'FORTH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '232500662e80eac87a677a3cb7bb3c12',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '400be0b6af3bb822d612d5a3c4d739bd',
    PRODUCT: 'USDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '94e60d48b7315cd4403c6b3f4fe76093',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b2e0f641b84700598e8cfafb8a73520',
    PRODUCT: 'USDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e882fbe957233982a7e8bfb471e5485e',
    PRODUCT: 'FORTH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03a689b59c1826dcbf0e09976d2d8a11',
    PRODUCT: 'USDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d523f674d5106d20f996881ecbff7d1',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '371b9c98d0e35f1d9abbd8ce5b70c78a',
    PRODUCT: 'USDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cdb240605ce289783c3036ea149f5fb7',
    PRODUCT: 'USDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e1f47bc95ee86868d12892e74dff643',
    PRODUCT: 'FORTH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd983430295f8dd8a8194764b8a2f93b8',
    PRODUCT: 'USDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'efd80851852d50446b8c13357bc92f24',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9b055311def9d996b780bcc76d76474',
    PRODUCT: 'USDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bcc758af17e89035bec657d871b1f538',
    PRODUCT: 'FIL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f2512bbb8490e40e6444915ab796f5ec',
    PRODUCT: 'LRC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dbfa063fc2acd47fea4c7822c367b751',
    PRODUCT: 'FIL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '60ab777fd256b44403471524396e3dba',
    PRODUCT: 'UNI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85fd618aeb977589b85a11ff3ab6ed79',
    PRODUCT: 'LRC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '83b2b5c34e6cde31d872aaf2c2baad97',
    PRODUCT: 'FIL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81b85eba8debcdc8042556d15ba9c40a',
    PRODUCT: 'FIL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c8dfee815e740b6c206cfde79b0405f',
    PRODUCT: 'FIL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '79d45b6fbf090621f7c8e4e6d7cd32ba',
    PRODUCT: 'LRC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72d58fd8a0f6f2b7456292ab91efe2da',
    PRODUCT: 'FIL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '504a857cd46351237c669de44c9c40b2',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b54f6d3aa8707aa41859e58c68d22c8',
    PRODUCT: 'FIL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae9fae4baa4162930dee562a5aaa3e46',
    PRODUCT: 'FIL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f6a12ca1ee9e0d5497a4cb19ed702a1e',
    PRODUCT: 'FIL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72abb754e148b67e809fbff67511b9bf',
    PRODUCT: 'GAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e8cce69911e589321baffa42780ecc24',
    PRODUCT: 'GAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '98ab5f8b5180ae6b99cccc3c91126c46',
    PRODUCT: 'FLOW Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc382721f2934ed4040f8f5a277292b6',
    PRODUCT: 'FLOW Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6ab38c424eb55ce4547854a2dbef922',
    PRODUCT: 'GMT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cddf1cfc549cc20f75ff776a81fb0811',
    PRODUCT: 'GAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '564120b39b2b53bea21846f1179be10a',
    PRODUCT: 'GAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b251145e64d99bb848d4faa3c667bb8b',
    PRODUCT: 'FLOW OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f904fdc87c1c73939ce31d5ad2d1a7eb',
    PRODUCT: 'FLOW OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fd915dd7cad762aa8b0252d20210f34f',
    PRODUCT: 'GMT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GMT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-28',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3dba47ec3d47cae07a8136a24cdacdaa',
    PRODUCT: 'GAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f53c5e8fe61b756d27091b605b6ab8b8',
    PRODUCT: 'GAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b1f565ef3a40f108ce32343de62f57a8',
    PRODUCT: 'FLOW OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f3a6d13bd4cc8d2ca7d83d3b7405b7c',
    PRODUCT: 'GAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '58e402bb7728902d6c458c76d60ff500',
    PRODUCT: 'GAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ca9a847ee58740b9abed2b2e0947b953',
    PRODUCT: 'FLOW Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b1f97caaf727d0095cd11c5ffb66740f',
    PRODUCT: 'ATOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '480005fba960b160ac20b43bcacf1f61',
    PRODUCT: 'ATOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5635da282232df83c152ff3747d3ba1f',
    PRODUCT: 'KNC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5bfe10442cdccf519f95ce4a68d9cb9',
    PRODUCT: 'KNC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b37941867f8ad5eea2c96dc4abda706',
    PRODUCT: 'ATOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '75eb1e3246bd4bd45bd55c412a2603cf',
    PRODUCT: 'ATOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e1596e500541b9ae0d30b84ba516f6b6',
    PRODUCT: 'KNC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7cccd6584165294fe1933953d142f7fe',
    PRODUCT: 'KNC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '49378ade31f5229775e0bceb58e157c9',
    PRODUCT: 'ATOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '732abadbf709fad1e40d037137d8c13b',
    PRODUCT: 'ATOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7246809b4f2141b57ea2c54b0b851060',
    PRODUCT: 'KNC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ea8ea2983b126ce33f511dc45cc2ae56',
    PRODUCT: 'ATOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9f0bf8cdbdc40bf21b23edd76f24bb90',
    PRODUCT: 'ATOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-01-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dbcd56e70bb23194bd4ca9c3af8d162e',
    PRODUCT: 'RNDR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '809aeae37b7f94f0ca04614c22c3eb83',
    PRODUCT: 'UPI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '421e5732dc9c93c7ebe6b36bdbc9927d',
    PRODUCT: 'UPI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b6f8d0a2fa1a7a81f2d33b6ea947ee79',
    PRODUCT: 'RNDR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd8151324c98d7a841df4dc542f4be629',
    PRODUCT: 'QSP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8ead3a9d903a6049580cfccd16a95bdd',
    PRODUCT: 'AVT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '63565b9d61be4524908ca05c97a0a77a',
    PRODUCT: 'UPI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '24cdfc0cf9922ada104e32b85dbde56c',
    PRODUCT: 'UPI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '98b21e8b5c8d0e280f735a3d42aa6da3',
    PRODUCT: 'QSP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '972a83d7e56e1ce8e2fabcc54eedbf71',
    PRODUCT: 'AVT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4ad99718703f27119440a370abd54d6c',
    PRODUCT: 'RNDR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '69af9c90dde4809836db1ece422506ca',
    PRODUCT: 'UPI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '52ca69c4463be1013b342b3d2459265c',
    PRODUCT: 'QSP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c8802fdccb51e2679d062db90c1ece4',
    PRODUCT: 'AVT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f63146486de77db8b19af9f1ad1769e3',
    PRODUCT: 'RNDR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81c3041e3c58c5e371c0df02198aeb35',
    PRODUCT: 'RNDR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a13b5245e185c1d0dd1bd0eb9b412409',
    PRODUCT: 'UPI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '296fd3338719926cd106233b89826e68',
    PRODUCT: 'LQTY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e48ccd19ae838e722f89351871ee5550',
    PRODUCT: 'NCT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae2644f4757ac05e2514834913b992a3',
    PRODUCT: 'INV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'INV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '79a24a17361f6820cbc964b7e74482bd',
    PRODUCT: 'STX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9f6d4aa84b97edabdc087817778b22b2',
    PRODUCT: 'SHPING Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd80ee196c4f502aff10cb09b361e412d',
    PRODUCT: 'SHPING Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b2f77785af530b09ad3c44502cadba8',
    PRODUCT: 'STX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '51f221ee3026e1d895d6083535c8ffac',
    PRODUCT: 'LQTY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '228443c5f9a7aaecf82ac3fa611bee9c',
    PRODUCT: 'SHPING OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2c39909f6a8c59a92c2236aebfe61e14',
    PRODUCT: 'STX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e2f9709d09005e09fe1776e654a60ed',
    PRODUCT: 'SHPING OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6788598dea8310bacb825c483e186e98',
    PRODUCT: 'LQTY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd7638fc12bb05ad7d9fcff699d737165',
    PRODUCT: 'STX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '21304d7808f942245212b02831309bc5',
    PRODUCT: 'SHPING OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f8cc7fff7f01a6f5d9d4a96a92f3315',
    PRODUCT: 'SHPING OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd27d1a01e1642c5767dd932c7ff07afa',
    PRODUCT: 'NCT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '724c1c5f92476ee6ec11227f985ee48a',
    PRODUCT: 'STX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '246572d07c43353e04d8cdf703624e19',
    PRODUCT: 'SHPING Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b5814b4e7a96d80eba83189e1baa1b55',
    PRODUCT: 'SHPING Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4c592cb9071f92cb004a4a61676e8092',
    PRODUCT: 'STX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4745e300d06a840642856193a156860a',
    PRODUCT: 'SHPING Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHPING',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '92391a337afbd2a3c05531d3246aaf29',
    PRODUCT: 'SPELL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93f182ac5edc65ab524e9de13377b70a',
    PRODUCT: 'IDEX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3816e5ced4f05bb26b25b8f653e0dbc1',
    PRODUCT: 'POLS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '699667606fa6f1808587e5fda691b699',
    PRODUCT: 'POLS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '545caaf021480a6f3179dc730e0f31ed',
    PRODUCT: 'SUPER OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '455fcee3fe9a27a4316430455fb3848a',
    PRODUCT: 'POLS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ccd27dd2b6c86d55962a057254af6b7',
    PRODUCT: 'SUPER OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f17c9d56a26145a3bac9d6e3492b8d92',
    PRODUCT: 'FOX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ed9e1efc6fae3830f3f69194a944e6ca',
    PRODUCT: 'POLS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '76a8c03fcfc2f3812ba675c3c2418167',
    PRODUCT: 'IDEX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '044b175af42e843ca272b0c32f808cd6',
    PRODUCT: 'MCO2 Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8bdb8ed98095bd1277489302b859221c',
    PRODUCT: 'SUPER Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2e77d1a060704bf91ace778e15212e4d',
    PRODUCT: 'DOT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '194439bbb86f03ba0381d188eb13fd82',
    PRODUCT: 'DOT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '399328fbdf21cf47001a4a08dabfadac',
    PRODUCT: 'DOT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3df31d6ee1ac43db4d54ef0d758717ac',
    PRODUCT: 'DOT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '581732d50aed56e6a9889143fd18a654',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29f50961819b48ee1c2aba74e739da2c',
    PRODUCT: 'DOT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'af65d5f195784f8f1d25aab497ea782c',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5285c6cf07271d44cb28636b9e85a612',
    PRODUCT: 'KEEP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KEEP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3a303e118267de8aef7ed7f41aa400c',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c4c1cad110268772729b5f1f4bfc99b4',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c87c0c126494f0c130fb9af31db61f8e',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93bd1a376d6a6efef486933d897fd0e6',
    PRODUCT: 'DOT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e2d6e8a802028ee61ab1fb1cb92de1aa',
    PRODUCT: 'DOT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd64060006e9a961852cb043ee256ba80',
    PRODUCT: 'DOT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0a75c14580556e1adc95af9de412fcc3',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4d03609f3e771a8845dd7350b47c7bc6',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85cf9090c6c4fbb205efeea520bcee33',
    PRODUCT: 'DOT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'be8e60b481dabb86b5c85bbc9ec72656',
    PRODUCT: 'DOT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0c525c14f20374b30a5038c3f6dea150',
    PRODUCT: 'DOT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cd0d298ca236f52c0c4ba2b24bab3d75',
    PRODUCT: 'KEEP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KEEP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd17beae403834fb05421d1f6f2f583e9',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd7e8e71e41399aedbb3f29a38458aa06',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7717e018483c7b41df362c50f1ce3f17',
    PRODUCT: 'CHZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'facc15b9576b124e60fffaea574cd1af',
    PRODUCT: 'CHZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e23fe9ed398917a119b5ec9b3df0f77',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c6663734cf1aecfd4f29ed16a8ac572b',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ccc603bfe5450eadef617c8d72d0b589',
    PRODUCT: 'MATIC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ba2e0d9304944476ba4ea46eeb318406',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aae52aa5991c828e0c498bc418c92951',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '212c69bc28fbeff341641e1bc60399ed',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e73fdbd559cb317204660980d3cf2475',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '38ec0ea3dd77fd2f2d535423e622f1db',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fd45cfcbec7cf3e022ed7662a36877bf',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'de0435c836ea089fcf406e295dcc83d0',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '568a0b70b4891300821fbf72c619d835',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fb0c675b5d0f1cd82ad798e7d3e4e833',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e5c8f77c773028e6ad2f5c7d3017180',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'CAD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2016-07-29',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-31',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b887fcf16cd004253be9f48ee9d7c2d7',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'CAD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2016-07-29',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-31',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a4f3f40cc2f92fd2a7ea4212211961a',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'CAD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2016-07-29',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-31',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a4d87d95c3918f5210667c7188576d84',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef785058fea2d664bb5e7504a4a5d24b',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dbe52447f01e556b64aa4b8096d67ff2',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ffbc1f8ebafa2785667df54f0b44b57c',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4c28744210b643d3688c105e8c66930f',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '56132e02cb27977e157467cd5d14eca8',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c11804b0408a81681feb066d68618341',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9224e65d5c72251b2d6cf145a690b19',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4fcc3f98ea0be67e9cd1dd04fea119fb',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c81f3cf37cffc8bf224360a806497120',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7e6f41015e5c18d81e3d968af24849f0',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f6da866c6f1b65656bab124955c842b',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e8ffc851ec6719a733d9d9d966a0feba',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'CAD',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2016-07-29',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-31',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '708c42d535ee3075625a7d55d04fb974',
    PRODUCT: 'BTC Exchange Inflows & Outflows',
    PACKAGE: 'Exchange Inflows & Outflows',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2011-04-29',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: 'db9b060575a2f1598e67187ebba3cb63',
    PRODUCT: 'ETH Exchange Inflows & Outflows',
    PACKAGE: 'Exchange Inflows & Outflows',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-07',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '373a1af339b948b4d10a464942ab71c3',
    PRODUCT: 'BTC Miner Outflows',
    PACKAGE: 'Miner Outflows',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-01-01',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '263f2407950c2eb42b17668259c5aaea',
    PRODUCT: 'ETH Miner Outflows',
    PACKAGE: 'Miner Outflows',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-18',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '74595334b42bb696b13190f6c46da8b0',
    PRODUCT: 'BTC Hashrate',
    PACKAGE: 'Hashrate',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2009-01-09',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '21a1e876c9f5936516c4a0909d1a8c65',
    PRODUCT: 'ETH Hashrate',
    PACKAGE: 'Hashrate',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-07-30',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '7a43819ef6da43c8ed38e2697338a65d',
    PRODUCT: 'BTC Large Transactions',
    PACKAGE: 'Large Transactions',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-01',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '65465f3ed5023b7b1121c734dadf32dc',
    PRODUCT: 'ETH Large Transactions',
    PACKAGE: 'Large Transactions',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-07',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: 'a014111ea1a83c679e827e91bce82af7',
    PRODUCT: 'BTC Unique Addresses',
    PACKAGE: 'Unique Addresses',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2009-01-09',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '40d34284104d74957b0d62033221fde9',
    PRODUCT: 'ETH Unique Addresses',
    PACKAGE: 'Unique Addresses',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-07',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '71ec6ec7b107dc04728b783087bd526c',
    PRODUCT: 'BTC Blockchain Fees',
    PACKAGE: 'Blockchain Fees',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-01',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '43b0e7d29c977f47f9d37f295d6b7ac0',
    PRODUCT: 'ETH Blockchain Fees',
    PACKAGE: 'Blockchain Fees',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-08-07',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: '822e4190fca5be02caed8669c3344631',
    PRODUCT: 'BTC Transaction Count and Volume',
    PACKAGE: 'Transaction Count and Volume',
    QUOTE: '',
    SOURCE: 'Bitcoin',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-01-01',
  },
  {
    CATEGORY: 'Blockchain Data',
    CATALOGUE_ID: 'ce53edf19dd38818c56373b537331d2c',
    PRODUCT: 'ETH Transaction Count and Volume',
    PACKAGE: 'Transaction Count and Volume',
    QUOTE: '',
    SOURCE: 'Ethereum',
    BASE: 'ETH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2015-07-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3fb14d0968ea18b9c0f0709e73638229',
    PRODUCT: 'ICP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ff8a2ea760eacd0d82d406c1200de704',
    PRODUCT: 'ICP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4aeec7c87fc9ca434793b94826df0405',
    PRODUCT: 'ICP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1437a24bbf4487b520606d860a92df72',
    PRODUCT: 'ICP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd40fc75298d94ff81b9f4c49c752809c',
    PRODUCT: 'ICP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2af39c8b9daae9befaa707058a5dcce0',
    PRODUCT: 'ICP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c316731689d39d957cb42d444384b504',
    PRODUCT: 'ICP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a895025bb2888d515e8372630ff898d',
    PRODUCT: 'ICP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82863106d826748f4a6447601f16e8ed',
    PRODUCT: 'ICP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2636589fb67071fb17f7389c9d839c25',
    PRODUCT: 'ICP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6110590d4c20c1105181392610dc05b4',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a5a54cc2b31722f3e6cae550086dcdad',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '028eae41b455c511e6c7f1da0bbf2db4',
    PRODUCT: 'CRV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '966879df653197588248afb775fac2d6',
    PRODUCT: 'CRV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82865f1b2c219a308799b17ac81a91fa',
    PRODUCT: 'ANKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9dcb5b873f64638668ab86795bbf9632',
    PRODUCT: 'STORJ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1569961caccb3f50ad7e736643136c23',
    PRODUCT: 'ANKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '68bd5508b1c66e75bc2836eeede8cae2',
    PRODUCT: 'CRV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7b9f743dcd7d9fcd7d0b6777e4912af',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dcde4f4b78acba4a23a3a0af3511267b',
    PRODUCT: 'CRV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7b86f8aa104f221161cf6f13ef64536b',
    PRODUCT: 'STORJ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8629a7b044626af58fb9363dca5f4cf3',
    PRODUCT: 'ANKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '850ea6835a9106d1381aceeac5ad94d6',
    PRODUCT: 'CRV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '68e7b27398330dad90d3179bf1f5e89c',
    PRODUCT: 'ANKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '24ae50dcda71aa42ddab1ca460da2819',
    PRODUCT: 'ANKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb90ec803c17149ecaf46de307a24d1f',
    PRODUCT: 'CRV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fa8a0fcaead54fcb120b08278832d212',
    PRODUCT: 'ANKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '01c6441f8b5ddc543c8b4a42f8733847',
    PRODUCT: 'CRV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5064849b00030bd17450719965722f6e',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f5634dfb8fd1b93bd9a794ae3f3718a0',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b5ad1cd097457e18e44777d0321caeca',
    PRODUCT: 'CRV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fa364a1842ca86683d739a53a1de0953',
    PRODUCT: 'STORJ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '526bbb63a9909a7b32bf0e93e3c499fd',
    PRODUCT: 'STORJ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3c843e0c082d7d8dce1f90bddc5fd30e',
    PRODUCT: 'ANKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da01a5d2ab5843a557e9f94a455bb7e0',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd50a8473185c293524cdf0f47bb9b5ce',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c04c02866cd903a057b8b67b7afdf8a9',
    PRODUCT: 'XTZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b0a0c26da07700686d1c201e05d6c47d',
    PRODUCT: 'XTZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '67cbfcf29979482bf96a395c0b554b4e',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ecd23d6a61f8261efccba373408f59c1',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '15a3654961a3c061c28a6cbab26b627d',
    PRODUCT: 'XTZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aaaf477574bad4e6394cc139843e4733',
    PRODUCT: 'XTZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '808c331425007c943f2f584ce50c56e2',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0297f87f60e75f9eb7033cdd5efd0e3',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd757c524b167a7bbb09c3528791ed915',
    PRODUCT: 'XTZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2975c94c93d5344168d991f56bcfb61b',
    PRODUCT: 'XTZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb46d74c1b9181235d0110c5d2bfa6e0',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '511121cc0ce186e69559abcc540e60bc',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-06-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '871c396d02d938e2b85146f8f665eb9f',
    PRODUCT: 'XTZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cd7dd9d5ebb7caef1fd1b4d5e70bf4f5',
    PRODUCT: 'XTZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-08-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96969795f5384a4d405f8b6056958bc6',
    PRODUCT: 'RLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '224acc099ab179764b85b683ad33ee0b',
    PRODUCT: 'MASK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d92cbd0c619d6c9935310a829a217b9',
    PRODUCT: 'MASK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6e3b9edbb49de13fd33d014e024e6ac5',
    PRODUCT: 'RLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ff5e067bf821e5e8d0914c97ae39cf8',
    PRODUCT: 'MASK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f9cadec062d8ac6c3ec84597b7c6882',
    PRODUCT: 'CLV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1bf40c9a95ae8ccfb5fecf4fe973c3c2',
    PRODUCT: 'RLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef02d24121521f98466454152c2845ec',
    PRODUCT: 'RLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0cfb1fc41040a384ef1e606f5576981',
    PRODUCT: 'MASK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c88208f05095edfa4fc50c5426b8d655',
    PRODUCT: 'MASK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4ee2a16e206069b7c093df7ca81e5068',
    PRODUCT: 'MASK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef62ec3aa85beed68507cd5a57e9a2cf',
    PRODUCT: 'RLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd32eeb23939d55385f5f3a554ab4aa2d',
    PRODUCT: 'CLV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd1de12cc66e2304faa74b6b16686d5e8',
    PRODUCT: 'MASK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ca79e1832223b689184bc51504aa8e5e',
    PRODUCT: 'RLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '956f2c7586cbf654d35ab0f06eb1bee5',
    PRODUCT: 'RLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0bf5a62a667cd92a0622101c729e6cd0',
    PRODUCT: 'CLV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6df898a3a5f77837f90c33dba1e94f4',
    PRODUCT: 'MASK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5505fcfa13fb6bb2035d16d4e447a54e',
    PRODUCT: 'MASK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2663b80299493631cbd9aedc710aa1ad',
    PRODUCT: 'RLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f302b9140291a3fb0e6ae2614340afa8',
    PRODUCT: 'MASK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdb7fa8da469b5f71b68af15e510e335',
    PRODUCT: 'CLV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e59010449bc502323f44992696206865',
    PRODUCT: 'RLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f9bbf0e9824b46ff544f53a5e925afd',
    PRODUCT: 'CLV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5ae7a0b5cb8022d4aa3349041c2351f7',
    PRODUCT: 'RLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd1cae7b06b95f09a8f7b68ce35897307',
    PRODUCT: 'MASK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd336adb18a4e846777ed6d071b3e4529',
    PRODUCT: 'RLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0d37a1339f0a63498a54c05d3b435cde',
    PRODUCT: 'ASM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'de2eff42028794e018c4f42ef1eb7ac8',
    PRODUCT: 'ASM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ec138371e266617e794c730f1ca71692',
    PRODUCT: 'SUKU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '20ec7474d537a94fa45318685768b286',
    PRODUCT: 'KRL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dd287317ac7f04332550a8738cb8485f',
    PRODUCT: 'ASM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5adf33ef88482fccfa0b1afe47b4a00c',
    PRODUCT: 'ASM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ab3c42ec56fb6e51bed04ae8103f638',
    PRODUCT: 'TRAC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9601f678da75f00a7ff9748b92e1d19',
    PRODUCT: 'SUKU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2718a833a1016fe36a2a2214415085ab',
    PRODUCT: 'ASM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04bbca6f36c4ecb3b1592ef27df7943c',
    PRODUCT: 'ASM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ddf5c1f39212dfec0bfeca04797f3c7f',
    PRODUCT: 'SUKU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd182b8f97ef62f6d0e7564c454274a9a',
    PRODUCT: 'TRAC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf824f4bbb530209dc7c5a63a84a846b',
    PRODUCT: 'ASM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61e0b20576bfeb22d9a565e7291dc5b0',
    PRODUCT: 'ASM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ASM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4a6d9737a16f551c2b96a45f0af11b42',
    PRODUCT: 'SUKU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bd93257669d2cda3f13b3f2104d7ddec',
    PRODUCT: 'LCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c763c10c99792d92ba148eb178df1fe3',
    PRODUCT: 'KRL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc82343e13b015151b404618fca41f47',
    PRODUCT: 'TRAC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2b2966e836bd51cc065c606fe471f2ae',
    PRODUCT: 'PERP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd545513b6c78c6b9e20484c6c196451e',
    PRODUCT: 'ARPA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e6554535b54667f170756126199220c2',
    PRODUCT: 'PERP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a3881afd282860c9f523a744da14eb30',
    PRODUCT: 'PERP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fc3e6babe1530934fac1cd4b71160b18',
    PRODUCT: 'ARPA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e06bb95e273972b0a19146d4fae920f2',
    PRODUCT: 'ARPA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd19653d1e65125b49046118c031c113a',
    PRODUCT: 'PERP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c789bccd89b79072ee4021df9b6554ba',
    PRODUCT: 'ARPA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50ab806f2673a28064663e6271ad10d2',
    PRODUCT: 'PERP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '330e28684d415ad90e454344a9c7562b',
    PRODUCT: 'PERP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '425358f754f7fe6ec615ff362acf7c22',
    PRODUCT: 'DDX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd86bec90a53f1892258084f04fc3f4b6',
    PRODUCT: 'DDX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '99614fc9baef162390c2a9f4b4997ea7',
    PRODUCT: 'XYO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '31f2f66ad7ee92aa7461b2d8f9bd30a0',
    PRODUCT: 'SHIB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe1360b849e88e16958c257cf3ff2990',
    PRODUCT: 'SHIB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4f221b76784cba24e3df3135988bfde6',
    PRODUCT: 'DDX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd99d986c2880b8991ac6ff6792e25ae9',
    PRODUCT: 'DDX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '000c4dd92469edd0586d43e6753cf216',
    PRODUCT: 'DDX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9bdccf904c7c496461804202b9f97be8',
    PRODUCT: 'DDX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '671b8076aee8973fade17e762e27cb29',
    PRODUCT: 'SHIB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdb766a7ee90dc8a901e17e4da51d7b2',
    PRODUCT: 'RGT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RGT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bba0ca6aa71750970673550a1c18a364',
    PRODUCT: 'SHIB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '124a42d18f0a4a2f5c4422298c426d41',
    PRODUCT: 'DDX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '90a4690dce9fe3860b9ee351ee6124c4',
    PRODUCT: 'DDX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef9ee2019da5c52a4c4b53d4ef3fb22e',
    PRODUCT: 'SHIB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '23d19edd789459e65b7f3e1b5986f33e',
    PRODUCT: 'RGT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RGT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4c10a9af32dfada176fb9ee91554254c',
    PRODUCT: 'SHIB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '78af93ec9b9a8d0b0182c143654a9fed',
    PRODUCT: 'ETC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3cb5cedc0fa1e97e8a8fcd404a774d3e',
    PRODUCT: 'ETC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a54c165a1f4781a27e69478310943730',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1e90c8669c54fbed467150e4767a3d30',
    PRODUCT: 'LTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1a56837769c231379a672afcda51b8eb',
    PRODUCT: 'BCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a209a214960dab5880c417e6ea7cd999',
    PRODUCT: 'ETC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2b609907fc91bbce82935b99c4498dbc',
    PRODUCT: 'ETC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '69ff513048c606f6e41b570c7660bbe2',
    PRODUCT: 'BCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f4e4e0d5b62755d63433b7739af496f9',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '39cd047abc28214da560c927ee4a5fd6',
    PRODUCT: 'ETC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7cc81a2b1eaa3c47e10b5bda26f35840',
    PRODUCT: 'ETC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b223c74b08169e5e1705fa0f89418ad1',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '77c3b9ef36ff67848cde86b81e9846b2',
    PRODUCT: 'ETC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '77b13b3354a894aedd7592619b9b04ae',
    PRODUCT: 'LTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9ea1859e5ce666588897adadea7a379',
    PRODUCT: 'BCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3fa39b72fef6dd1286e147de4bd9efaa',
    PRODUCT: 'TRB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9fc9be9ff3e9dbcb9c26b02eec2f1850',
    PRODUCT: 'MIR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'abbc3e91cf56a2f847786ad74201e823',
    PRODUCT: 'MIR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '01a8d8caca8e75e2017960b740a8d32b',
    PRODUCT: 'RLC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '53448f9cc1f6797ca993e75f4d011a45',
    PRODUCT: 'RLC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6ed723c80a35c347a854fed610747653',
    PRODUCT: 'ICP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7488c60d8c8f24c56bba82888a324790',
    PRODUCT: 'ICP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '78865233cb37f6e5816ce0ff744445ea',
    PRODUCT: 'ICP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'de8cd80c04ebcc2f506a2d8804ed79b5',
    PRODUCT: 'CTSI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eca04eda21653b17b7d627892aedc0b6',
    PRODUCT: 'TRB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '00474004e87e04ab843f3750aa4e7bde',
    PRODUCT: 'RLC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1b97bdb61bbe39c303ae32a6e709db93',
    PRODUCT: 'MIR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '203c64b5e98596a317711c23b10bb917',
    PRODUCT: 'ICP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '886712f243900baecc3d91a5d555715c',
    PRODUCT: 'ICP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c8c1c227423d01b3fb991b7f8ac340ec',
    PRODUCT: 'ICP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '26d15584f20475ea22de0ae61f315403',
    PRODUCT: 'MIR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '51e6eacb2407dd2ac5cc4fe57501b56e',
    PRODUCT: 'MIR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7b26fa31a5c4bfd9f1f385a0bdeee407',
    PRODUCT: 'ICP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee4d8ccdf8867e13b86e5359ed5865cd',
    PRODUCT: 'MIR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f8514d9420899bdbda6f8ae7a257095',
    PRODUCT: 'CTSI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a89eca05c5a0f910f7df2a1ca4bfbc6f',
    PRODUCT: 'ICP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a0eaeacf00396c913ae0b65f99512143',
    PRODUCT: 'ICP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a098f15580377ff4980483f22bcece9',
    PRODUCT: 'ICP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ICP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a3990fa48599b7631210337ffca2a3f',
    PRODUCT: 'REQ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5131e4156d8d6fe11ac911db5125196',
    PRODUCT: 'TRU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f057b109f50b54bef185f5767a91d30c',
    PRODUCT: 'WLUNA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '32f5dabf4171d9070416383be0d29bed',
    PRODUCT: 'REQ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '203955f47b4d10b87a53c8310984b476',
    PRODUCT: 'AXS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '862effbe6e02b279a78fda1cbeff948d',
    PRODUCT: 'WLUNA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04a64e130b20c96e70b8d82172e54857',
    PRODUCT: 'REQ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e8d64f337c23d901cc4cacfc8ce71a9',
    PRODUCT: 'AXS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '330e44fd84a482d5942dc397389a5880',
    PRODUCT: 'REQ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ffbc6ef16ebabc7ffb1ca0940a8897f7',
    PRODUCT: 'WLUNA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3877b90940598c5cf76e40657205e5d9',
    PRODUCT: 'AXS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8cf6ec7b52d66f89801025cafa0766e1',
    PRODUCT: 'REQ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c6d3c709755290e8980634288d635363',
    PRODUCT: 'AXS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ccbe3ac08aaea57abfc0a33df98b8451',
    PRODUCT: 'WLUNA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b6066e0ae2d61d02a05daad46f9aaa8',
    PRODUCT: 'REQ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '522e32e3bd2e20a51523a7d7c482a42d',
    PRODUCT: 'TRU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fea9dd3519b976aac5e0a9580eeb0c85',
    PRODUCT: 'AXS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd586656a7acd340022c9644efe8e4d92',
    PRODUCT: 'AXS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '755d5102c8ab8424788ecc432db5366b',
    PRODUCT: 'AXS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '99b38dcd9d54e95d012d7f74d143d2e2',
    PRODUCT: 'TRU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c46f8efbcc2b933bad5bd95f8caabd0',
    PRODUCT: 'WLUNA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '665862871aa292ab48459893ee8ad8e8',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '843d330541d4e939ae6d3ce53c02abfa',
    PRODUCT: 'ALICE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALICE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '45623c916657be1d9689225a1f17267f',
    PRODUCT: 'LRC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6faa9b51e43d904ee972c36d23b298fc',
    PRODUCT: 'MATIC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f1af559e6a3446ca5e961a5a769d9d6',
    PRODUCT: 'ALICE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALICE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'df1a23eff5e1d6cc97bc46d73b49c3d1',
    PRODUCT: 'XLM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4f1e5f2dee97197b441f036c585d7703',
    PRODUCT: 'QNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f8efb3086576365073b53f6c48fcb600',
    PRODUCT: 'ALICE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALICE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'abfa6c62e6407f032e91cd65721de2c0',
    PRODUCT: 'ALICE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALICE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b319e28a848605e48b87f5b8376d23ab',
    PRODUCT: 'XLM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ffecb16b299b89ce489173f765f7277',
    PRODUCT: 'ENJ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8dec6e48432526c6a836e0b085a3aa58',
    PRODUCT: 'BCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-12-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '281efecb01fbb364bb26bac942a2cbea',
    PRODUCT: 'BCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1d9370eae27abbb9a16768698160e56f',
    PRODUCT: 'BCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-12-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '06204cb2864f7b3b9ed99a3b547bce32',
    PRODUCT: 'LTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7bd1382155e09c4af3b3f87c8ee9e98a',
    PRODUCT: 'LTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2fc1e1479f216e2b3ca74342cd5d6867',
    PRODUCT: 'BCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-12-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '58c5f36606cc74f134d879461c984701',
    PRODUCT: 'LTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2016-08-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '012c92af4ff9f23aa1222272de59ec6f',
    PRODUCT: 'LTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82052969b6411badb947fe32cbf8588b',
    PRODUCT: 'LTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f15ab815583b4c0d8ed133c7b1fce229',
    PRODUCT: 'LTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a5f3f552539ee25a09148d5b8afd1f01',
    PRODUCT: 'LTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '570c8b42e38557d086c18a2e99bf2d7d',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd8af4b8db834bc72dd22d1dceaea91d5',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd96d178ce402009ed70ce006a0a774f6',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5700bb23a53bc0f3ade051cf992ea84c',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f3b8744ebbb3be6231ea466fafda14e',
    PRODUCT: 'BCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2017-12-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '40eac1b93604fea5f6a9bd79ab41b1ed',
    PRODUCT: 'BCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '598dd22d356dd59e5443e54b749b9ab8',
    PRODUCT: 'AXS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4482c858a8ceceeed7dba0fda29ef7c8',
    PRODUCT: 'WLUNA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc2b900d18f2a37e3e7d593c4fb9c578',
    PRODUCT: 'WLUNA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93dc377096f566036359aea0dd5daf73',
    PRODUCT: 'REQ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4102cff039ed85d95ec426469a50c4e2',
    PRODUCT: 'AXS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a07ef75239e9ebb8e7c2f4a8fb4dd6f',
    PRODUCT: 'WLUNA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c54369917b1fba3940c1c2262bd12a54',
    PRODUCT: 'WLUNA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5411a2a8ece60bf9c810e8519da2d8a3',
    PRODUCT: 'WLUNA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3d0addd033ae8dc5fe4a4a8713efd641',
    PRODUCT: 'AXS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7fe47a5e0606057d1db6d84d99b244dc',
    PRODUCT: 'REQ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3b14fecfa5581f48d23d460f2422919',
    PRODUCT: 'CRO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c12fbfd60d55d710b4a4f2f03e00016b',
    PRODUCT: 'CRO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb68113cd02e0f47290d033631f30e47',
    PRODUCT: 'AVAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '06733aa1cbcca578180e3a5fab48ddba',
    PRODUCT: 'LCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ff86776a18aa54dcaca14a2ad465ed8d',
    PRODUCT: 'CRO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '793cd7efcb1677b951e981aa9dbb9ef6',
    PRODUCT: 'LCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b86d6beeede4eda40e0eeaa0cebbaf84',
    PRODUCT: 'CRO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '817df15fd3caa010a13a172f0af41eda',
    PRODUCT: 'FARM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '172867e4b0ca468b955f91fe02bbff66',
    PRODUCT: 'CRO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '76001158c71741435880863ecfdd222e',
    PRODUCT: 'CRO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d7da9973c30f8fac492095f331bd4d0',
    PRODUCT: 'LCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '37e1b433c6135774a00b3a0176ade5a0',
    PRODUCT: 'AVAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b0a418cbc378e17c91fb165f27df93ac',
    PRODUCT: 'LCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a59cf5e93a1b0975a7f38542d21bbbc',
    PRODUCT: 'FARM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0d76d091dcb949bc4a8ff15fad7f065a',
    PRODUCT: 'AVAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '10e2de9467bbd7a3cce7f84a62dbbe0b',
    PRODUCT: 'CRO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '77c4bb7bb33bfb83c099bcf383aeb6e1',
    PRODUCT: 'UST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '63c9d4a64bc75f53b28cedc516fb14bf',
    PRODUCT: 'IOTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e5370d36a06d137570dddb45eba0c348',
    PRODUCT: 'UST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85a877aaccf33ec7cc8a8a365a9cba39',
    PRODUCT: 'IOTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d765841d09bf2b851f1f820be3376df',
    PRODUCT: 'IOTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ca2f26b70780aafa870a1847bffe86df',
    PRODUCT: 'ORN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9f4769f2e0883fad11d43d37d4622be',
    PRODUCT: 'UST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8efdcde7fd425655be8157893f1eb6d0',
    PRODUCT: 'IOTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9a20d58690c62dcd958cc85b9b068bf9',
    PRODUCT: 'TRIBE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRIBE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '112b397cc661ee39c76a8e52d3f5f6b0',
    PRODUCT: 'QUICK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QUICK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe6eddab8da44cc11da8a3482ba3c371',
    PRODUCT: 'ORN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd7d9232c96b1acf56c3869e1c2bf9cfd',
    PRODUCT: 'IOTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b8620858a9a348d56c941d3ed7adaeb7',
    PRODUCT: 'IOTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88ef8b4a4bae33c0a03736ee8a8875d1',
    PRODUCT: 'IOTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f332c2b02823d11aeeed92fe4328ef15',
    PRODUCT: 'ORN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3188cbb457cadff455b77140d9ecbd21',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'UST',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4d6acd9d7e80f8a9b5139c4dc37c1eaf',
    PRODUCT: 'TRIBE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRIBE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '39abed3765e4e2291f656bead06ec2f8',
    PRODUCT: 'IOTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '335ddbe7b96da2f75eb89bac86e8200f',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'UST',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0feec84219a722d91b8148398fa91389',
    PRODUCT: 'IOTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4f08c5e82e5f3575b548d1bd2233d789',
    PRODUCT: 'UST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '26291380f988ff9cc50020e477cb8679',
    PRODUCT: 'UST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '516a9ac4315ee05f3736f94dc7e14879',
    PRODUCT: 'ORN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '14a0ff8df73acb58af7206d486b0980d',
    PRODUCT: 'DESO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '02e7d40dd9102779b7e7301151a87ffc',
    PRODUCT: 'DESO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '164f3dcbd5398950dacdcd8417799b85',
    PRODUCT: 'DESO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6db0d8f170af33d27fc35a2d4d43057',
    PRODUCT: 'GFI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '06f5c5c89aa616ce4e2bb84da58bf224',
    PRODUCT: 'DESO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03ff55a99222e04f5f339af8e946b832',
    PRODUCT: 'DESO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '49fb09ea2784c934c0bdcfcc4c13afd0',
    PRODUCT: 'DESO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '323e9258e721c3de39dfc79a2927d1ff',
    PRODUCT: 'DESO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b3ba0cb0e0202d4491e3097d96a7405',
    PRODUCT: 'DESO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '64ef5b31a813dcda26dbd24f91e1a58d',
    PRODUCT: 'DESO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '658a211e12d94ba9ae1af461e4946c0f',
    PRODUCT: 'GFI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '74d8729367761b5cd25e5974e31858b8',
    PRODUCT: 'DESO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ddc8f7c057f67169d46b55985043a19c',
    PRODUCT: 'DESO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '501bb0c29fb5b433c77c524a60bc0387',
    PRODUCT: 'DESO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DESO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f4eb7b7b72b85eac010ce38b44a827bd',
    PRODUCT: 'USDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd863e176242be604f6bf29b46ac4c3d2',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e6416e6b0dcf98305fdc14e622f99be7',
    PRODUCT: 'USDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5dd746307b3f69e754c39d1fdbff45b8',
    PRODUCT: 'USDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '95bc89c4a1ef49fce32637466b68637d',
    PRODUCT: 'BTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '683ab3885023599a1db598d45eaf97f3',
    PRODUCT: 'USDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47c54240aa170188a0a3981ef7309484',
    PRODUCT: 'USDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '18e5ff4289932241198f1cdb9a43edb1',
    PRODUCT: 'BTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72c8d1651afe87338b8117021650c180',
    PRODUCT: 'USDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'USDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b5d2570fcd3a6ad8723849aabc499f43',
    PRODUCT: 'UST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b4a92362183d2e4d05e9593ac46b39c8',
    PRODUCT: 'BTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'UST',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ba71cda42504baf067a38449dcae2212',
    PRODUCT: 'TRIBE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRIBE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '13667846a7789b807f319343b782d0aa',
    PRODUCT: 'ORN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ebb45123e622e650a75b973100516ac4',
    PRODUCT: 'QUICK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QUICK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '58c3f9210f326b056d335d77bb70e4f8',
    PRODUCT: 'IOTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e98a46180a0c5dec65bea3380267341',
    PRODUCT: 'ORN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c6a442930d51c5510e0b648db4dc7f2',
    PRODUCT: 'IOTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '76179312e27589554126d38a8b0d7cbf',
    PRODUCT: 'IOTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81a199bbf7cae6cf7310bd929063ffe3',
    PRODUCT: 'ORN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8cbb446eeb0a70311b10182bb0168f38',
    PRODUCT: 'UST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08cfc305e10fcfff6c29a522a8ffbb02',
    PRODUCT: 'QUICK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QUICK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '83121dcfa16092dcbad8c422ea69adae',
    PRODUCT: 'ORN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3a54a57c4841e295dbf57906f569fe9',
    PRODUCT: 'ORN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '62a06463776e3f345e48b48f565b90ed',
    PRODUCT: 'IOTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0508986972a1cc16099592733c6db18b',
    PRODUCT: 'UST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '42233c551d7f8b58e60ac90ff60645d0',
    PRODUCT: 'QUICK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QUICK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9448137212284b426ebc47840ed97547',
    PRODUCT: 'BAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '454984325c02caaa1d0d73119823c771',
    PRODUCT: 'REN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ca23381b56fe42f7fb1dce2431502bbc',
    PRODUCT: 'BAL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8af2d542bf64260e61334cdcaf6ae64f',
    PRODUCT: 'BAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '516e7fceb323f5726ffd8b1693173671',
    PRODUCT: 'REN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bde81303bd770389bee189d4403d81c3',
    PRODUCT: 'BAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b04bb067386b526784e5f1b533281de5',
    PRODUCT: 'REN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ab48eb8df15d4dee62a106cd29059077',
    PRODUCT: 'REN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b13f735e2517ada65418d5a95e7b8af',
    PRODUCT: 'BAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d83e1a7e8e559591a7bb140ec7bed20',
    PRODUCT: 'ORN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd534dc11aa5d9027e20c4fce0c1516c7',
    PRODUCT: 'ORN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '34c31f9dce074e24815abd7f06e31298',
    PRODUCT: 'BTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'UST',
    SOURCE: 'Coinbase',
    BASE: 'BTC',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b0086d803410dfea17d2cf9bc9b1b10',
    PRODUCT: 'UST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a193cce53a2ddfb18be7c328e385c56d',
    PRODUCT: 'IOTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1c38f90ad833cde3c1167a005a2d9b79',
    PRODUCT: 'UST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd87c607c683fac1aab62f16dc2748df5',
    PRODUCT: 'ORN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d0c88027b1c79a820f3a2f48a6900fc',
    PRODUCT: 'UST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08c300ff044311b7981f5614ad4bb5ab',
    PRODUCT: 'TRIBE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRIBE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c71e0a78e48f9603a99e9dff0516963f',
    PRODUCT: 'IOTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e16d3ef190b2abd7809be2acba4db98',
    PRODUCT: 'UST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UST',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6673cacc7e801253ecb012c7cd452009',
    PRODUCT: 'IOTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IOTX',
    COVERAGE_TO: '2021-11-04',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '403bb6ca31552c7792c1f407c2ee0b8e',
    PRODUCT: 'XYO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e130290dfaf2820a1d3510cd5d8a5199',
    PRODUCT: 'XYO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41199e57658e1e17c5035db42248136e',
    PRODUCT: 'XYO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd598ca78ee8f92b2436542b3471d8fea',
    PRODUCT: 'BTRST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '896e94db618f11b1d33303539477cd72',
    PRODUCT: 'BTRST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c10b605205c5fcef015e99705cfd1695',
    PRODUCT: 'XYO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4177b5fc9fdd974a490790352721bc95',
    PRODUCT: 'BTRST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '417aa21f9fdb00310b49131a45c56b6e',
    PRODUCT: 'BTRST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5640036356fb0839536e1d907375b411',
    PRODUCT: 'XYO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61686af89ad19942f635fec4a5b053a1',
    PRODUCT: 'BTRST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c81e3da1516e64444c29e932ed503ec1',
    PRODUCT: 'BTRST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '022452f3b605c37f16b72ad4f4699789',
    PRODUCT: 'BTRST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a1808ed7cd18eab94896c970c6e189d',
    PRODUCT: 'XYO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0c726c6fbd302ef6a8d05ffc48e7b83f',
    PRODUCT: 'BTRST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '65b8c9f2fd453ec7b6170bea6446cafc',
    PRODUCT: 'BTRST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f01b0a97405fce2cbb44feb9d0c072a7',
    PRODUCT: 'CRPT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a481389feeefb40a6159cc629a45c4ee',
    PRODUCT: 'MINA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ac9209109f45944be70af72080685581',
    PRODUCT: 'MINA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd41a07a77f764e9b87f64e16eb5522d2',
    PRODUCT: 'MINA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9bdbb2bac395ccc3a11c0e6f7275cf8d',
    PRODUCT: 'BTCAUCTION OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTCAUCTION',
    COVERAGE_TO: '2022-05-26',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b079d5215cc74efd6876c499c4418dd1',
    PRODUCT: 'CRPT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f785c4ab1b9a1c08799314d10b92c57d',
    PRODUCT: 'MINA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc6db1ea77e504ae7e4864c4a04db17d',
    PRODUCT: 'MINA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a4fdbe1761bf8741242a0a36edb76c3d',
    PRODUCT: 'MINA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e5b580b35e6c2cbc7651f2dd08ec28af',
    PRODUCT: 'CRPT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a1b2b6d6dadd889686ac60201d887e9d',
    PRODUCT: 'MINA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3c5c5bb0913d9b7d3e564de390ee20f3',
    PRODUCT: 'MINA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a0e7573813c3e5687e6fe179c25cf07',
    PRODUCT: 'MINA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '895909db3059b3b670f0bc2d0c597a66',
    PRODUCT: 'APE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b9e30b9f61c2d004b8a6c431113a73b',
    PRODUCT: 'CRPT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ecdaed7d484cc84a021506533efea229',
    PRODUCT: 'MINA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c91f15645dff3009ab805b3018a75a3c',
    PRODUCT: 'MINA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c0eb348f817e1281d5c9c343092be42a',
    PRODUCT: 'BAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cba1d94837af9266e56de9f2745b2785',
    PRODUCT: 'BAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e03ea1d7644749d7710175ed02fcd96b',
    PRODUCT: 'NMR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8c03be2b1547e0ce6549531efbb06ba8',
    PRODUCT: 'NMR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9f8549f1ecd2a896298e4d25b0521c1c',
    PRODUCT: 'NMR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2c957a34458983414426484798535cda',
    PRODUCT: 'NMR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '12e2deca4ea8ae80c6609cacf647c1d5',
    PRODUCT: 'BAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8ef3820614a460c31b158c5f76c39d89',
    PRODUCT: 'BAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b6a08359b100fe46e52cae32f5f35ed8',
    PRODUCT: 'NMR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '198eae93b148c014dd18bf42d8a5b86a',
    PRODUCT: 'NMR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'af20f5885a40d59be2200f68ce19d04e',
    PRODUCT: 'BAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f248f77573c9b66437bdf0a72544be19',
    PRODUCT: 'NMR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '223e812e06ec37b7d1a9515f84ff6865',
    PRODUCT: 'NMR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7443b010609a812583d467b67377532f',
    PRODUCT: 'BAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1b79d9811e1d0ca9a5c8279f1d878504',
    PRODUCT: 'RARI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RARI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1df49e1d5c8672d89743ddaf1507c5ac',
    PRODUCT: 'BADGER Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c87f899006a436568ba0bced3920f3d',
    PRODUCT: 'ARPA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29ccc6c4c8e65c4f1416798191793234',
    PRODUCT: 'ARPA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '818b3f9ef76505d3658a6e9a3caa1b30',
    PRODUCT: 'PERP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e50c9b4d880e4c652434f98752997813',
    PRODUCT: 'PERP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4123775228a8eb0d3a084f654482fad1',
    PRODUCT: 'PERP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '994a7736b93aa8fa95380d23ae27f5e5',
    PRODUCT: 'ARPA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4abe50bba5d9c2bb9197a9e25919ad87',
    PRODUCT: 'ARPA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '722d1e6ae6f78e350cb36d4cc866a659',
    PRODUCT: 'PERP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4fc148a76e92a64289747cd524152f00',
    PRODUCT: 'PERP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cc5ae3f8da06f30db05cb8d9210e1551',
    PRODUCT: 'ARPA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c80e22ca9bd89e5bd25c6a72a12adf29',
    PRODUCT: 'RARI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RARI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fd4fa378088e57c86854aac7250b0366',
    PRODUCT: 'BADGER OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b83a409823efeaaccb7d8535f363424d',
    PRODUCT: 'BADGER OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '367980eba6d87b7a57d53d1307d837e2',
    PRODUCT: 'PERP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'PERP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b152629ac06b52c235768f4014f9bf4',
    PRODUCT: 'ARPA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5fd7d4b4875fe67b0fde7c7f36363579',
    PRODUCT: 'ARPA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ddf9ea5e79c3a79209811a9bf1243b4',
    PRODUCT: 'BADGER Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BADGER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e2811edbd191f85f6130b06fe5cad03',
    PRODUCT: 'ARPA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ARPA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd27ffb62155a602c3f0eb7df9ee36a97',
    PRODUCT: 'AAVE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a43617f1f7646574b72afb5e1e050969',
    PRODUCT: 'AAVE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '23ab55578dc6fb62cdbd693b15aed4b1',
    PRODUCT: 'GRT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a71ed9688f687cf1114844547687d4ae',
    PRODUCT: 'GRT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'faa1d87a06ef4dfe3ffe4944ab9c8e11',
    PRODUCT: 'GRT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cc4f87655d845924b54f42e3dae1b8d6',
    PRODUCT: 'GRT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da4748615c8382211e8c8c5dc857730f',
    PRODUCT: 'AAVE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b055217c899a276e98a6105446af5803',
    PRODUCT: 'AAVE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '279ef172d53e55f4c0bd9100ff74b95b',
    PRODUCT: 'GRT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a7473070493585191d4a618992a7094f',
    PRODUCT: 'GRT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4cf981563979da60b5de39d1156882cb',
    PRODUCT: 'GRT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aa2acf379074c29a901d591dfbfa87b7',
    PRODUCT: 'GRT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '727cdc8442579df3dd74802e7cb9bce1',
    PRODUCT: 'AAVE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2a26d7468dfa9a1c7201830623f1a10d',
    PRODUCT: 'AAVE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '36a68dcbd0388e81759a9a855a02e7af',
    PRODUCT: 'GRT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '737a8ec432112e3884c3a92070a8dff7',
    PRODUCT: 'GRT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a8c1b501e2ae78e7c598f978e242ae6f',
    PRODUCT: 'AAVE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11716185e471c3017c857455489aa4b2',
    PRODUCT: 'AAVE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e23032bf1f40ed215e72e24422f5d1f',
    PRODUCT: 'GRT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '90b86f6aeae0fd9097be40e95239d4c7',
    PRODUCT: 'GRT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9f1d8c3bf3c95570f4ffea6adce5c60',
    PRODUCT: 'BAT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8548117f132f2720d0926883196a0f81',
    PRODUCT: 'BAT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '14034919c59a385c91e01f0c0bd93573',
    PRODUCT: 'MANA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '933fe83af9aab7846a0852f1691c9601',
    PRODUCT: 'USDC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd20e4b37fac589e473f3bc3a32fc0116',
    PRODUCT: 'CRV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '28716e80e1a36af9a0fae05c621d601f',
    PRODUCT: 'STORJ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'STORJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '44e5f24c84d3e37a8b168de16df73baa',
    PRODUCT: 'CRV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4a5d80f4fb060039c8332b7f28d7c226',
    PRODUCT: 'ANKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ANKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a2be9a811b0397dc747456dc273bc876',
    PRODUCT: 'CRV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CRV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f0ed7f9872d52f48a33cb4c01e5b8a33',
    PRODUCT: 'WLUNA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e286b50c4072dc6e7cba71060dda069',
    PRODUCT: 'TRU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd62b496bc553196b4c7f56f2baa11bba',
    PRODUCT: 'AXS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5d8eb9dfde5c5fc68eca3acad7c5c67',
    PRODUCT: 'REQ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '467027f5114a9ff66fbcc937df894078',
    PRODUCT: 'REQ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e384170ab7d26e918d7f8476f3a2552a',
    PRODUCT: 'TRU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '580f4e8e6b33ddc6715753fa69527829',
    PRODUCT: 'AXS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91eef187592d24d5f2d6433aa4fec4fe',
    PRODUCT: 'WLUNA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9f68abe2bb35ea6c02f3a9ca692b59a',
    PRODUCT: 'REQ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd60813305d555c8168dfe75e5a6223dc',
    PRODUCT: 'TRU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82d88e9271db13962ff37fb7417e6e2a',
    PRODUCT: 'REQ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6bedb374c681d7ada71c00ec6bdd3fb9',
    PRODUCT: 'TRU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ba7958d06bc4d1fd115ee1ad266ccef9',
    PRODUCT: 'WLUNA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '62424cf33c05d6641d63b0d1de9cb1ad',
    PRODUCT: 'ZRX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8087f67aebdd7819e4dda57ca04579de',
    PRODUCT: 'ZRX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2c247f320f2d0697ecc9d6b4c6c046e7',
    PRODUCT: 'LTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9da070a9e35d763d365f946045c1802',
    PRODUCT: 'ETC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '092e232a9dc42939bcffb47ec527a595',
    PRODUCT: 'ZRX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9de52da95f711330fa8cecc3a00aeaca',
    PRODUCT: 'LTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c31b9d3c29ac5c2f250e9cec38b7b6e9',
    PRODUCT: 'ETC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b9677c2ca034082ea0e0dfa050396909',
    PRODUCT: 'BCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ffbacdbcf0bb130839c1147e3dd48cc6',
    PRODUCT: 'ZRX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b8fd05fff01c1c500a12d992ea706bc6',
    PRODUCT: 'ZRX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ded7bd0e20ab7bb69c0ff1049650c90c',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '238a2b8f767976104338f2e2d7c51132',
    PRODUCT: 'ETC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-09-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03089483f89584ed80924bc5a41b34a8',
    PRODUCT: 'ZRX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f7369b6303b582b55e3641e99187cad7',
    PRODUCT: 'ZRX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZRX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5e36beb9337a05b50a6d65147ef0ea02',
    PRODUCT: 'CGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '046f60af1bb34f275ebea54e79ea5e20',
    PRODUCT: 'UMA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1493c26aa8bd3de35dd0d966809626c1',
    PRODUCT: 'UMA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c43877f41b33bdb0b8e48531a3e8aaee',
    PRODUCT: 'CGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1f599db6c0ec3914344f3b91b9d04a77',
    PRODUCT: 'UMA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e353676907a0f6c95b685e517dcb611',
    PRODUCT: 'UMA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '05c4893cc2c3491676625e1879ac8dca',
    PRODUCT: 'UMA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cbea6b90b158bc8c7291e319cfc3b9d5',
    PRODUCT: 'CGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72d4623afc324f55e02f2fcb71cc79ed',
    PRODUCT: 'CGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1d8bbd9cf83f2c8b8134994562fa33d6',
    PRODUCT: 'UMA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2e44fae80fae30f893d7c0e49cbbcea3',
    PRODUCT: 'UMA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '704813a1699dc479d823806e26d48ef2',
    PRODUCT: 'UMA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1024e9ee780005b66a230274f1f83035',
    PRODUCT: 'UMA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a489955e75797879e195e358763375ef',
    PRODUCT: 'UMA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-05',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1a0166e0862f733c97b17fbeb3b84799',
    PRODUCT: 'UMA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fbc22b67bc414981f93b393de65a76cb',
    PRODUCT: 'UMA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9760e7d2accbbc8b01ce367adde722d',
    PRODUCT: 'UMA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '66028b0ca1445c0f1f9dc0cc4c31f3de',
    PRODUCT: 'ZEC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-11-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '31cc27a8bd026d8a2f6a7726123a731d',
    PRODUCT: 'CVC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'faf1ab6bf0e20ff9bcbb5511715a3aa7',
    PRODUCT: 'DNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3dfc1cb9c441a3fde7cded7e84b9b31',
    PRODUCT: 'LOOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd78604a0d549c6261388cb25106eb360',
    PRODUCT: 'MANA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b871da996f6808069b02b0cf333e5c4',
    PRODUCT: 'DNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe18e21dcfa768b1f77725564643b762',
    PRODUCT: 'MANA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11cde0fe8bc2525141cf103e343d1a31',
    PRODUCT: 'MANA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cc33bbe2fb0884837a716cb4d8b83d0e',
    PRODUCT: 'LOOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2babf59aaaf960965b60f5b7ede3aeb5',
    PRODUCT: 'MANA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '83861f14c8ad96a799d59fa5db171c6a',
    PRODUCT: 'DNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '05015c839919762adbc6ff83012b4ce8',
    PRODUCT: 'CVC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-12-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '10b95a04e4d3cc8c9db34212bd30c9a0',
    PRODUCT: 'TRU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '05f9a7a7d3dae3783dbd9b9be98c1cef',
    PRODUCT: 'REQ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a159891803c72af7a464cde43c13bcdc',
    PRODUCT: 'WLUNA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-13',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5e7f40053e84f7e512f3973d82cf8c1',
    PRODUCT: 'TRU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '37ad89bfe3d5aeea46311e4997075417',
    PRODUCT: 'AXS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e73203dda4f803a3421f42851ced3fe7',
    PRODUCT: 'TRU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cd921cc3354207afece45e3c9726322e',
    PRODUCT: 'AXS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '623c9d58f2d58fea2612c6ca87d42d01',
    PRODUCT: 'WLUNA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd598251c37256b80912caa8ecef4836d',
    PRODUCT: 'TRU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3f2227fe6ed2e37e9747d16ed2e2dbc6',
    PRODUCT: 'AXS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AXS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a7e47ecaf545e4e743fe33e8b6e2c896',
    PRODUCT: 'TRU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee564e78fd174a3c909a9a93e5971df2',
    PRODUCT: 'REQ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '35818a75eb36019284fd76863de51bfb',
    PRODUCT: 'TRU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '24125dd10557eb782902990a787dbd75',
    PRODUCT: 'WLUNA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03c8b967392de7a4a8b4f3e226cb834c',
    PRODUCT: 'TRU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aead7b16c5c1793487ca557ce3a65702',
    PRODUCT: 'REQ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '17ef1161ba5f0b95634a2cce57f0c326',
    PRODUCT: 'REQ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc45b9b7bbbe8b6b38a94c920eea1831',
    PRODUCT: 'REQ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'REQ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '56c149e1024ddf3a54d442d21107ad0b',
    PRODUCT: 'WLUNA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'WLUNA',
    COVERAGE_TO: '2022-05-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '263519b2d743ddb9d928401932a7da32',
    PRODUCT: 'TRU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aaa31d8521dc9759b7d930fedb5a79a1',
    PRODUCT: 'ERN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '31a9e5b6dd9458f20758529704ae98bc',
    PRODUCT: 'GLM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5785a87b90dfbff778a5ad4bec618cfa',
    PRODUCT: 'SYN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SYN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd2c3170b3bae5dac95c7ede3077ef00f',
    PRODUCT: 'AERGO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AERGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf5ae5bdb0db8943872acdbfacb6d0df',
    PRODUCT: 'HIGH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'HIGH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '00af469af3915985d96e78244e088fe9',
    PRODUCT: 'GLM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '26adcdf2c618747fdac59b84b1838fe2',
    PRODUCT: 'AIOZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd0c3a70c7ae6d39d30648902e6ce1172',
    PRODUCT: 'GLM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '296c5d76507b28d6d51f29d1e4c72778',
    PRODUCT: 'ERN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5ed42f242d56d2fe09a48eb61823c30f',
    PRODUCT: 'AERGO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AERGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7690473f2449e035116acc95164e4e76',
    PRODUCT: 'SYN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SYN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c406d125a522abbebec6656d0acef4f',
    PRODUCT: 'GLM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b87d97ec28d462f58414006b39f27167',
    PRODUCT: 'ERN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fe10d07e064d0428a4f4d0533291e30a',
    PRODUCT: 'CTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '963ae58b4b00af1f8327799ecaeda97c',
    PRODUCT: 'CTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ea63bb30bb6244bf020d00f34abb1bca',
    PRODUCT: 'MPL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MPL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5513a6c067d1c503fc6d7a4975428a91',
    PRODUCT: 'DIA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2def975d6ec210519c123a01e41fb5aa',
    PRODUCT: 'UNFI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b0cb115db342f03e3fdf0b964106f9a',
    PRODUCT: 'CTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '38f2dca0f882385457e35065d3fc51be',
    PRODUCT: 'MPL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MPL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '044f1e2375e85aa1e94f6892f9d16724',
    PRODUCT: 'CTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a70dbf47e8bafd78e12092848afd128a',
    PRODUCT: 'CTX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '95e530e259f423cfaf206ee00b232bf1',
    PRODUCT: 'UNFI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04955833c62ea86fce2a467d293026cd',
    PRODUCT: 'DIA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3ad1a60c24f78b3fa48588d7e501961a',
    PRODUCT: 'CTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f8dddf4c35b668dae3a44e8f54ea85c3',
    PRODUCT: 'DIA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1107d1fa7de4e715eb534ccba2a7cdb8',
    PRODUCT: 'MPL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MPL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4a7073f22fd4cd133f3657fd256282d3',
    PRODUCT: 'CTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b01b403b86e8d160f9e560137b4d74b4',
    PRODUCT: 'DIA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '116b34f40bdf39409a6b27a9c262d80e',
    PRODUCT: 'CTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '02b7d99878d6ca262e2378da3fb3dd1d',
    PRODUCT: 'PLU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b4621c65d3b8ef8a2895386ea20ff0ae',
    PRODUCT: 'FIDA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '43f3ac1c761d1c768b40b0aef19312d3',
    PRODUCT: 'FIDA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5a3d722b1351f986202395cd55b8d680',
    PRODUCT: 'ORCA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORCA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '26a7c55e11dfda646a5b450314b1799c',
    PRODUCT: 'FIDA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6be97d54ad4beb5b2eb7ff781b217d4c',
    PRODUCT: 'ORCA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORCA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '497702e5797c89e7769cdee9ca2cd59e',
    PRODUCT: 'FIDA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5816781ad6d0bdcd050f7c31cbd6ad52',
    PRODUCT: 'FIDA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72644955740defc793b8fac3af693fc8',
    PRODUCT: 'FIDA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '13ad591db2be020d4798dc1b0601facd',
    PRODUCT: 'FIDA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '73c789fd47bb59b7de67da58547d8ecf',
    PRODUCT: 'ORCA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORCA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6ce4bf56e9f23f961a16d9d73d9e4723',
    PRODUCT: 'FIDA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e77cb5eb1000a5ae3adbc60e8918f3ae',
    PRODUCT: 'FIDA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85e27804c24f63b37431a9a01425f88f',
    PRODUCT: 'FIDA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '410a9fa4bfc9776394d071ea1a58c0fa',
    PRODUCT: 'FIDA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd55c6314d648552fa7fb1e7fb11d28df',
    PRODUCT: 'FIDA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FIDA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c77ab48142e85a85bdc80e2375c5374',
    PRODUCT: 'ORCA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ORCA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1cb2176330dd6d2436ac6c58930a3189',
    PRODUCT: 'ALCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '254c574dc4a23236b7c2e07cc029ef75',
    PRODUCT: 'ENS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4995e32be1ffa94cc593da5d9ad845c4',
    PRODUCT: 'ALCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81f8e73d9eb9dca4b33a99ba3509866f',
    PRODUCT: 'ALCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '64f92630e45cdecdb61f9988aa16fd25',
    PRODUCT: 'ALCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '17ba8f7650f52d7765fa8f2a365d47ff',
    PRODUCT: 'ENS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b17bf35cbbc6c0ab18cb5cb2737a52c',
    PRODUCT: 'GALA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c92136acd85771b524a6c89fc6a5d540',
    PRODUCT: 'POWR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1ce1f03d3f19fdb18fcf9be74e460779',
    PRODUCT: 'ALCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0db0d457a00416ef95f75f49ed14aedc',
    PRODUCT: 'ENS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c00c12991d90515777936c2d61de31de',
    PRODUCT: 'GALA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3e1802c133454eae5babcb1c2379a988',
    PRODUCT: 'GALA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e5ad6a67dce0d3089123320ec790d0c0',
    PRODUCT: 'BAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3f4330e03caea3013f80b2d2d72dfa9',
    PRODUCT: 'BAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cca11b21999f47d9d356f99b0f36eaf2',
    PRODUCT: 'ALGO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29dcff96ba0fbfb01d79de63555e55ce',
    PRODUCT: 'BAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5ca3e5825a416ae0d09f623533308fa',
    PRODUCT: 'BAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bd0918cf458088ab7b50ea270195c3e0',
    PRODUCT: 'BAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '15589296fc910e4176722470b00ff4a9',
    PRODUCT: 'BAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1fd2e01804773663c192c026a288c6b7',
    PRODUCT: 'BAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'de9bfa2bcbb46132a563c4f1b228c173',
    PRODUCT: 'BAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '911f9008eeed8ef1d133d0023eafcf42',
    PRODUCT: 'BAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ff2dac6ed86391071b177c4b6487f14',
    PRODUCT: 'BAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6ff2ffbfbe3ce849d53c107d4c5272e3',
    PRODUCT: 'REP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '983a86c24fe1705f21131882cf110316',
    PRODUCT: 'EOS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee65baa533aff6497e7f6e781e2ad3a9',
    PRODUCT: 'MKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3c70ec067094934c1382f7be9ea72798',
    PRODUCT: 'EOS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '100b8a01cd43310826d30d94cfbe3680',
    PRODUCT: 'MKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2019-05-09',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3d0eea2fe080a37992ae7b6de20b8e37',
    PRODUCT: 'REP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c45e39577acc7d420d1d3f3dd5cf70d',
    PRODUCT: 'MKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3d5e99873d0c3a9e49e677397e7a6c2e',
    PRODUCT: 'EOS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb77cdb1f5246bc8f2394e7405c0ca93',
    PRODUCT: 'REP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd55fb375ba726cbfa67eb53518a27d44',
    PRODUCT: 'EOS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '061643890838bf65fe2733609f74e9d3',
    PRODUCT: 'MKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2019-05-09',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'efe8aa5190fd68b21ecba173ac0cca98',
    PRODUCT: 'EOS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '36a6415a5ae280ea8632c2f8c7b26ec2',
    PRODUCT: '1INCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b6bb30274c5f2849c2ef59218efafffd',
    PRODUCT: 'OGN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OGN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bd199a8b21df8a1fe29111994ca31e41',
    PRODUCT: '1INCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: '1INCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6be32e0208042ed682095781d59299b8',
    PRODUCT: 'CLV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bebedf60221a93c06e1245bfa21f0840',
    PRODUCT: 'MASK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '698ba8ff7edd61535265f0d7a92f30c4',
    PRODUCT: 'CLV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3f8ecfee949387acc3d0d798fd84f09b',
    PRODUCT: 'RLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b3c82c2bcfb4b2d1d580ddde500e795',
    PRODUCT: 'MASK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '308897b7ddd15ada945e3c3fa444b855',
    PRODUCT: 'CLV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '97108694c9b2fc205e5f6844f3bb72e4',
    PRODUCT: 'CLV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '992a8027f49b19d99eb744a61f9c890e',
    PRODUCT: 'RLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11cfea3e702ed6aa6f9a48e5705091cc',
    PRODUCT: 'CLV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '25ce69fada42fbe4c88f5c20fc4a51a3',
    PRODUCT: 'MASK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4527fbfefa738a19067f293763eb8aec',
    PRODUCT: 'RLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5103fdf4f59c76756a61699d90e6b719',
    PRODUCT: 'CLV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b8041760b38a8bc732e852ebe4ddc4d0',
    PRODUCT: 'CLV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c2cd0d52d8d2b2b436e0a5f19d956d1d',
    PRODUCT: 'MASK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '079f461597f7ae8dd682b1d42e1d7c92',
    PRODUCT: 'RLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84aa5290aad87b9d26f27b520906d060',
    PRODUCT: 'MASK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MASK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9bb83611319022d3ff44d5d65b7ad990',
    PRODUCT: 'RLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'RLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb1817a9e1829718f4666c54df536c90',
    PRODUCT: 'CLV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2455d751aae4879f21867bcbe922cded',
    PRODUCT: 'CLV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef1a53cf28b66512b22076a283933f57',
    PRODUCT: 'CLV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7c09b8ab05d835020edba9f3ba5c83ed',
    PRODUCT: 'BNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1a411bc9bd3eb8b4ff3ceb2b87edf40d',
    PRODUCT: 'AAVE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c90e05a504827499a1f570ff8d5fcee6',
    PRODUCT: 'SNX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '485f145b708f6466c4b26beacd87098d',
    PRODUCT: 'AAVE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '875724ff9cb86cd35021d2de018e169d',
    PRODUCT: 'BNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f6c9a68dbe35077bff8a8cb6b6c6db3c',
    PRODUCT: 'BNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '58cb3f69b65ad47f39a3792e0f01cdca',
    PRODUCT: 'SNX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b31d8f5566a9d743d0baa84fdf13ff2',
    PRODUCT: 'BNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2798790af0df92295c406c15145fe1b6',
    PRODUCT: 'BNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1ae1b82564108f2d9f38943aff083acc',
    PRODUCT: 'BNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4cc903cb86ebd20149e3b1b60a914f0e',
    PRODUCT: 'SNX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '613a2a48eb119fc6389647e64bed0095',
    PRODUCT: 'SNX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf19e7a961eeab2ecc9326e666fc10fc',
    PRODUCT: 'SNX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08e29ce598b7c8b1b4c2110ff7c51193',
    PRODUCT: 'BNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0b84386a938f3d20e3a17d61b587ba55',
    PRODUCT: 'CLV Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CLV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50ed3e024082b89e205d7d19e3cd47a0',
    PRODUCT: 'FET Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '83b38c3d44b2c8ac44ad98eecbaadabd',
    PRODUCT: 'PAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9e650c453b69cca755750500d173108',
    PRODUCT: 'FET Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '73112b88962901170fca8fc044420e93',
    PRODUCT: 'PAX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b889d21538a5a96cc3954a1dd35ab327',
    PRODUCT: 'POLY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b39a9ef85eed59126a7d22bcdd5a83f3',
    PRODUCT: 'POLY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ba27b8acf454c2d30b05183eadbd1ca4',
    PRODUCT: 'PAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '113d8bf29a225e4b635f8b566771cef2',
    PRODUCT: 'FET OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '33ddea98312ce2a71d05e40fcb57420c',
    PRODUCT: 'PAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c454d9c07186ad3894b0d56d640c3eea',
    PRODUCT: 'FET OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2f9f799e5313706aacb4fbb23b39f424',
    PRODUCT: 'POLY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8f25f32d4b6bb894ae406dc81b0a1882',
    PRODUCT: 'FET OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd2d7ac32f985786e6b2bff0c58e91834',
    PRODUCT: 'PAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e4adc8bb51e0816aa5cc789b86778565',
    PRODUCT: 'PAX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f0295a07ffc608f0150184173fc64e5',
    PRODUCT: 'FET OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f43b17da33d6a2d301e838fe41a84f7b',
    PRODUCT: 'POLY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2105aa50d90d3396cd9077d3169212f9',
    PRODUCT: 'PAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2db38c7ea091a7be375b3e85d75b61cf',
    PRODUCT: 'PAX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'PAX',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a26c51dc2ffd246bf7643532972901de',
    PRODUCT: 'FET Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '704848ce640f08aa9afe52ecbedb3dc3',
    PRODUCT: 'FET Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FET',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-07-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c0ee1de3858ae04aeaabfd835457d6fb',
    PRODUCT: 'ZEN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dfbdd658c7bbe9d95b8030ea8d63789c',
    PRODUCT: 'ZEN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ebfaaf27ba86e6965f87742b6603c2f',
    PRODUCT: 'AGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47f4497fcf8bdc88241a7f119b6d095a',
    PRODUCT: 'ZEN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50b9209b0330c0ee919693fea7a2dfb3',
    PRODUCT: 'ZEN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a1637aaf6b0936209b1de90e41291222',
    PRODUCT: 'ZEN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '006f52084b1909df1237292a886d7820',
    PRODUCT: 'ZEN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1bd6f5dca8cc3b2ba9b50687b8de01e2',
    PRODUCT: 'ZEN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ce55a5b445e457dbe6f6cb449921a1a',
    PRODUCT: 'AGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '25b6d0f488e92b12cb8ba1759b1f6299',
    PRODUCT: 'AGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '56538ed1f8dbc48218729113f7ec42e0',
    PRODUCT: 'ZEN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'db85990303ecd529b711ab06e4682c7a',
    PRODUCT: 'ZEN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '86b70cbbc04623cf95ad39e5e7e3ed69',
    PRODUCT: 'AGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b1fb7da9b2770742a0c1f482c4fd93e',
    PRODUCT: 'AGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e4f702318cae5aea0bdf120cf7eb7e98',
    PRODUCT: 'OMG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd4414ca1424d16c795fa5cb454a01a72',
    PRODUCT: 'OMG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d8d41bf13f4ba9e305ffd6436d7a65d',
    PRODUCT: 'MKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dd0bf655c396ca9d91b006fb3932844b',
    PRODUCT: 'COMP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b3e4df759492c1af0142ee0e2591ebf4',
    PRODUCT: 'OMG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47ee45a1ed2cc66b872711b5ddc3f18e',
    PRODUCT: 'OMG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd28d5c0d1e9e55001dea86bbb1e2b257',
    PRODUCT: 'MKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '17e7f9487ce20dc41613d3805d4e4d67',
    PRODUCT: 'COMP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf942d7b584aea27eb737d3dd4f64b18',
    PRODUCT: 'COMP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f542b0656a0fe26bf55f39f659deeb20',
    PRODUCT: 'OMG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f2a877d0ab04a558471dc1a0c9402c39',
    PRODUCT: 'OMG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9115826bf5eed7f126f271cdedac3a03',
    PRODUCT: 'MKR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ebc10e01cd95573286c0da72f595f0a2',
    PRODUCT: 'COMP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4562f80c28410a6aaaf854fa78641004',
    PRODUCT: 'OMG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ab23f4aaa82e5a50994e10f0f936ba62',
    PRODUCT: 'OMG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8cc77801af5ce7bc280969b094558a8a',
    PRODUCT: 'MKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5ce62fdf6c3c4a17a8b4aacf5763dad3',
    PRODUCT: 'COMP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c7c56bb602043862820b86c7c8866bd',
    PRODUCT: 'COMP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae8b747677765f3faef244b32e2afd1b',
    PRODUCT: 'RGT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RGT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f022d60cc17f6db93b0acafa042bffb3',
    PRODUCT: 'XYO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a9e488d337380ac5ec0376240697f067',
    PRODUCT: 'SHIB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5669e2fb028957daed7b8b698e882942',
    PRODUCT: 'RGT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RGT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '94af2cb29c4ab47ba8f1bd591b9f368e',
    PRODUCT: 'SHIB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b2705a395057ada4822c62664f04df4c',
    PRODUCT: 'XYO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b45dab655a3186aac0237d3ef49fef9',
    PRODUCT: 'XYO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'af866e2dc7c6d510cb6cb0e8099b3f19',
    PRODUCT: 'XYO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6f6be089ee37d6298a56eb0e9fe57942',
    PRODUCT: 'XYO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b90ffce7a051a430260e6e0672a1f9ab',
    PRODUCT: 'XYO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '378556b384d8d341457d05214a6c1fe7',
    PRODUCT: 'XYO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3cb0aec6babc633386bbc732d8d7525',
    PRODUCT: 'XYO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '517e8fb418adb6bab50d0fd75784c428',
    PRODUCT: 'XYO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XYO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '25083add068fb67f2e977ff8e727706d',
    PRODUCT: 'FOX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a2593ef5d12dd4cf7b2b8ab773e949de',
    PRODUCT: 'FOX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3238e0ea7879abf745bf17837041b5fd',
    PRODUCT: 'IDEX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '311716c5cbd20e16be0ccdbc580b4b98',
    PRODUCT: 'POLS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'acb4fffab7c8d07365c94a6cd6fc2ddc',
    PRODUCT: 'SPELL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '417f5559fcf052c30d09a4def6408816',
    PRODUCT: 'FOX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f763894bd17ff96dc9f49a68cad78412',
    PRODUCT: 'MCO2 OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f9ce776d5129a1f8175986687b880de4',
    PRODUCT: 'MCO2 OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb984f8aaf1a002a5643f1c68083ed0f',
    PRODUCT: 'SUPER OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '080977d94997c127afb1fe0e019d9a7e',
    PRODUCT: 'IDEX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e6b7139e2f44abe3467d1ea5bb080321',
    PRODUCT: 'SPELL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd548e308db1088d04769d42f0043cd53',
    PRODUCT: 'MCO2 Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '280eefdc27da7c78ec33de7a8a9ee0d7',
    PRODUCT: 'POLS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a4a6474d14691afaafcce6a59fec2fde',
    PRODUCT: 'SPELL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '66247e056a46e3bb9a3a06d8414c51ae',
    PRODUCT: 'FOX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdb4ecc30d9d77c287ca960c0fb2cfb1',
    PRODUCT: 'ROSE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e83cc21e65155c371ba684ea40be0fc5',
    PRODUCT: 'ROSE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a5a6444f174ec02e955e6df922aef2e4',
    PRODUCT: 'BUSD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-04-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bbccac1236a1483fb8c241e59e8f366a',
    PRODUCT: 'ROSE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b8e7f66f38c935b0e782806001f3e00',
    PRODUCT: 'ROSE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b1068ce1e75dd15aefc81313f626e295',
    PRODUCT: 'ROSE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a745bd5f60c92cdf2c6fd056952e5258',
    PRODUCT: 'ROSE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '25358cd4c2eec9f25097115fd9a75a13',
    PRODUCT: 'BUSD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9365d328c7c7dd5268029b2e372964fa',
    PRODUCT: 'MINA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MINA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1284d9bb2503417ae2fe004c1a5eec6d',
    PRODUCT: 'ROSE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '618268e80df5df5849a7d3c2b30f5f6f',
    PRODUCT: 'ROSE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ROSE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2f8fd12d0862f0578062d1bbf1842acf',
    PRODUCT: 'BUSD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BUSD',
    COVERAGE_TO: '2022-04-27',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-04-27',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2df593421d0b77fd2b9008ad72a115f9',
    PRODUCT: 'SKL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3df8d9e22c6883b4af898272b80cd5d',
    PRODUCT: 'SUSHI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '133802f691d36febb6efc13560add60f',
    PRODUCT: 'MATIC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6311877df45c8eefa8a51d7db18880be',
    PRODUCT: 'MATIC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4012a0197735ac6fc70497cf07f1c437',
    PRODUCT: 'SUSHI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41fadd6da700183f83b58581f9b27baa',
    PRODUCT: 'SKL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6db0090aa78d009a5908331cf609ff17',
    PRODUCT: 'SUSHI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8dda7c698d0c0ff25beb986a984e7beb',
    PRODUCT: 'SUSHI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7cfc0f7863410ddec9e08e9587daf495',
    PRODUCT: 'SKL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '843bc7c6e0d7ef846f84904e330b7c54',
    PRODUCT: 'SUSHI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7c037b14bd83d5e026dd4d4b49bfe780',
    PRODUCT: 'SUSHI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1a4a42e2e552c6e33fed66b4de785fd2',
    PRODUCT: 'SUSHI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7fbe50a3c6783113a0c45841f0bd8b3b',
    PRODUCT: 'SKL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b120e6735e0da657a49bfb81b75c0086',
    PRODUCT: 'MATIC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1d8913d9c808c06fb69433f6fa613d6e',
    PRODUCT: 'SKL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd3d9c3a609d3c72bd0924826f64fa12f',
    PRODUCT: 'MATIC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd81d499616981c28289cadbdf8fd864c',
    PRODUCT: 'SKL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b09c9078e6de2f09f7a3b7551afc9638',
    PRODUCT: 'SUSHI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f67e4f73af3b9c9490ce2f3a5e446ed9',
    PRODUCT: 'MATIC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9757b62435b52c8b7d540a3418f4d8b5',
    PRODUCT: 'SKL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a41ec1cb54d481f1c44c104c63dcb9d',
    PRODUCT: 'MATIC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8286985beae50aa646167bee0e1ff49c',
    PRODUCT: 'SKL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '77f639408a7279cab40bfa8bc0e9feba',
    PRODUCT: 'MATIC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bbe117e2043709c58f44be4a21b13154',
    PRODUCT: 'SKL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f78dfb0e717aa9076dc33588339291f6',
    PRODUCT: 'MATIC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e15eacdb24ac4f1a8f7beb5725588225',
    PRODUCT: 'SKL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '506cd242034b8e3a9d92c75ba67b0050',
    PRODUCT: 'SKL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fd585bcac50bc738443bea7d631f01bc',
    PRODUCT: 'MATIC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3cf760ac4624f4fc6fb9045b3916be97',
    PRODUCT: 'DIA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8077df909f74df14796de187d9a811b4',
    PRODUCT: 'CTX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1d97f115cc784c680ff5116d1c6f9ca6',
    PRODUCT: 'PLU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c1159f1d84da91959951e5469ffdff3',
    PRODUCT: 'DIA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4a40218c2c710d669ae128e16d534318',
    PRODUCT: 'DIA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41157d2fe457da82f2ea55a715408a34',
    PRODUCT: 'PLU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ce42cc5e1b034463794bbf5531dd18de',
    PRODUCT: 'DIA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aaa1012bbbfcacd260c225b306491260',
    PRODUCT: 'DIA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '283069e169fa75ab2b3a1a35d404d898',
    PRODUCT: 'CTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c50d55dc99f1b3836cce9704f0e702e',
    PRODUCT: 'DIA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '81abbf8966b42fe25da5dd68c66d3b9a',
    PRODUCT: 'PLU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f312d97409fb1f18fc3941584116b2e9',
    PRODUCT: 'MPL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MPL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b18ed2408b87b80b3d0a1c0e574a5aae',
    PRODUCT: 'CTX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '95c01b0b49d233b7727b06ac6790d291',
    PRODUCT: 'UNFI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '520ff2863063a0a0d197f069fca22fb8',
    PRODUCT: 'DIA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DIA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ff86656e74b9cb6563d2c25ac001031b',
    PRODUCT: 'CTX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CTX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee84c2297e2a4feee7f22f0f535b859d',
    PRODUCT: 'SAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4e42b49c2f6e11f63c4faa7f43d3b53e',
    PRODUCT: 'SAND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '478f71be0a5e7f76103e1418b0040008',
    PRODUCT: 'OP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-06-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'db658856fbbbd7fd390c788668d11578',
    PRODUCT: 'SAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82203994e2f3c48d4434250357092a87',
    PRODUCT: 'SAND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e9feb8ddfcafa4e6a41555d12c44e025',
    PRODUCT: 'OP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-06-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6e87b0f3887c41930a1d2c25222596f',
    PRODUCT: 'FLOW OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a97298ca8dbc640c7b543e5cbba7056',
    PRODUCT: 'SAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e36520d77ee6bd74b733c4883c70bf34',
    PRODUCT: 'SAND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f571595601ed662e17062b50c773ba2a',
    PRODUCT: 'OP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-06-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8bd9137b3ebf2ec910b63da65c8296a0',
    PRODUCT: 'FLOW Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FLOW',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '718e7a255502543b4d72ffd9daa9f099',
    PRODUCT: 'SAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4297944d8b72efb7b7869a7cc359faec',
    PRODUCT: 'SAND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SAND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-05-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4ef37c8fdee2f19aeb91109bed36c3da',
    PRODUCT: 'OP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-06-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '98856ffb2d98511334a04a98a7689309',
    PRODUCT: 'AVT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AVT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3ee687a3c58eb577cd6691eb789c2fb6',
    PRODUCT: 'QSP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7d1e743a2b2e65b283ec2185741fabbf',
    PRODUCT: 'RNDR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee65ee86d143eeead69d7373f0d82362',
    PRODUCT: 'QSP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '90dcd897fc7df38bc674b18f1da47046',
    PRODUCT: 'RNDR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91f143041d6d81bfd44b35ac4e47d63c',
    PRODUCT: 'RNDR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fbecc86f9f0a6ac250ab752f162a2a6b',
    PRODUCT: 'QSP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8bf0565edf7b9b74d4af7a425219ab6a',
    PRODUCT: 'RNDR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4ca60bcb90af0cc11b0c27ac84e1d41d',
    PRODUCT: 'RNDR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c1d9bd4fc54f687ccc77d913f48127bf',
    PRODUCT: 'QSP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ef7df8ffb8fed5a2275808ba77d1e6e',
    PRODUCT: 'RNDR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1bdc78ca815f84cb3136c43778bb8e2c',
    PRODUCT: 'QSP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QSP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '194c659be2e7617f7ddce5bc0b73f30c',
    PRODUCT: 'RNDR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RNDR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a7715d797ad9fda8c5a18579ac81b6f9',
    PRODUCT: 'GRT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '32a6c8e2ce2b81525498ce8349e71a25',
    PRODUCT: 'GRT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ea481873422e8466d714f064b444c1d1',
    PRODUCT: 'GRT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'afde3301c1460eca545e05f5189b651c',
    PRODUCT: 'GRT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'GRT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'df8dbf93d8ea1825eabbcc4398c35029',
    PRODUCT: 'MCO2 Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82e26df4433f2dabcbea18a8ce2ab5dd',
    PRODUCT: 'POLS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '719f9b1d2cadbacffdb74f4ba6c624aa',
    PRODUCT: 'IDEX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61a459f998cb2502c10befabf5684d46',
    PRODUCT: 'SUPER Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dec1fef3a7ce93d193a2b94696b5d20d',
    PRODUCT: 'SPELL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '750fbb40f3ec48dba15d770f318f5dca',
    PRODUCT: 'SUPER Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bed1ee9227d6d1c19470e2db28063452',
    PRODUCT: 'MCO2 Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ac48d5919f9e645b68a54650ed547fb',
    PRODUCT: 'IDEX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd33a82487e4f322022fa8f4345829955',
    PRODUCT: 'SUPER OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fd41cb6f2bd86cc8b2ca4dd3461f39ce',
    PRODUCT: 'MCO2 OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c47beb04babb979f81f0d87f1c3a6efa',
    PRODUCT: 'SPELL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b7f0afee2d14d4d3a2e7563dc3d590c7',
    PRODUCT: 'MCO2 OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MCO2',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cefda3eb9a52746b76d787a6dceec1d3',
    PRODUCT: 'FOX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a089e155b8e8a7d6638c6b662f592ca5',
    PRODUCT: 'SPELL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1b2de780a318c7aaa31722adfa83f433',
    PRODUCT: 'FOX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ea0e5327be26b43840aaafbe63cc7ae',
    PRODUCT: 'IDEX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '69aaa8a77c49ace5a41ff81f34c5e0ae',
    PRODUCT: 'SUPER Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUPER',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f83ab031254c570acbe5d5617d3e6611',
    PRODUCT: 'IDEX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IDEX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '56f29b1108c9cdbb0a18edd57b74b311',
    PRODUCT: 'SPELL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SPELL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '52c694daafe09e001f0176684e3f3855',
    PRODUCT: 'POLS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POLS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bed574e67768b3389659601942a67a31',
    PRODUCT: 'FOX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FOX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd92ac7424e9685bceacde8475bfe20c2',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd9c83672a1507c5117cad0ecc1218e72',
    PRODUCT: 'QNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b28d14f760a403f5cb47386cf9fdb69',
    PRODUCT: 'LPT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '202127c7ff10a02ff3ea3d396fadf5da',
    PRODUCT: 'BOND Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BOND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7bde8c2e03764941a47160b243a1f92c',
    PRODUCT: 'LPT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '305896f214aeb7f1815181db1c9d1913',
    PRODUCT: 'BOND OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BOND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41fd8a99b56f6dac0e0b88feadca112a',
    PRODUCT: 'QNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7fa7c0a1da2a4444fd2c4b8d83267bef',
    PRODUCT: 'LPT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f613f5c569b9e0ff072d0157d65a7069',
    PRODUCT: 'QNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd6c0e6d5ce7736753859dc6ef7e20277',
    PRODUCT: 'BOND OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BOND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'db241c504c960df658a571dcde71bfb2',
    PRODUCT: 'LPT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LPT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5b9c07e8b11ea184003a4db247a3142b',
    PRODUCT: 'BOND Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BOND',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '73d2bca654325caa4c1c863a4d901f90',
    PRODUCT: 'QNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b8da87da6dbef9dff92f23cd325d8a18',
    PRODUCT: 'UNI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c7db2d8111728994b630065694678e3b',
    PRODUCT: 'ATOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96138645bac2a872858e6daa1b3cdcfb',
    PRODUCT: 'KRL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2bc8447cc2055eb0e5f95b61ff6a2abf',
    PRODUCT: 'FARM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ecbab9acc9cec0eb26864967d8db720d',
    PRODUCT: 'ATOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6ac933170818a549ff429e423e86e28d',
    PRODUCT: 'ATOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5119841be56b5b416bf8b14c95a68a24',
    PRODUCT: 'CVC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b576884108828a680e0aacee312f265',
    PRODUCT: 'SOL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '16dfdeea3a404b167ea99ba5346ac498',
    PRODUCT: 'AVAX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AVAX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '00773ed6e605666b5bb6e8a3ff4b9c5f',
    PRODUCT: 'SHIB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '718b29ed087212194e6a4874d4402be9',
    PRODUCT: 'SHIB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7346ba96c1745429df8385bae48ee69a',
    PRODUCT: 'ATOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '45f3ef94968d8d4e879fe0b83d8ecf44',
    PRODUCT: 'CVC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a5313c801b495b2645914c97342936ee',
    PRODUCT: 'SHIB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9e2f16b982105496b804e9602cad4c9e',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '49d62e38f9376e2effa3121b69bb1ede',
    PRODUCT: 'KRL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '41d3a21e8380a5305009480d66dac14e',
    PRODUCT: 'ATOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '98957f10a643ef0db69f1be426d8b680',
    PRODUCT: 'UNI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae9a370bb6dbe26084d01dc1130745f5',
    PRODUCT: 'KRL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '541bcb5c74a1b13b93f5c0f4fa02a7f8',
    PRODUCT: 'CRO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7345f003ece4d17ab51b6d62bb8afe9b',
    PRODUCT: 'XLM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fce7bac92a6c43d8ec0d008fd9d13481',
    PRODUCT: 'MKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2019-05-09',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '366cb10864db8361b380f120991339a0',
    PRODUCT: 'EOS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d0c2e96acaf861c39d250b06009758a',
    PRODUCT: 'REP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ebd19838fea808ae9ac9dd9726ffa337',
    PRODUCT: 'EOS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'df98ac2804540745eec45ea34cbd7c25',
    PRODUCT: 'MKR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29dfbf1e20843f4a3a13fc6f4da5ff55',
    PRODUCT: 'EOS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7d0b5f43f4a226e4b4a0b6940dfa1ef',
    PRODUCT: 'REP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8f7ff0bd4515e57bf40dc76b03127a3a',
    PRODUCT: 'EOS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0e3fa65eec1358b690e1652ad79c4cf',
    PRODUCT: 'REP OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '24e291e0d023b547654fe0fdedc2e6b7',
    PRODUCT: 'XLM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd722218d23f70cec95598d6399bbc439',
    PRODUCT: 'XLM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ebf09f1303d8f957cd5c13d4b7b2635d',
    PRODUCT: 'EOS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f898bbed142f7054aca4a87dc01774cf',
    PRODUCT: 'XLM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-03-14',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9be4afe2a221111565cd7fad1736ee2',
    PRODUCT: 'REP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c547d8567b81dd0fc57c4e2cfb21e5aa',
    PRODUCT: 'EOS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '01a26476e8c5f46027e491bd1f22a886',
    PRODUCT: 'REP Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '31c5599419102d444ae6b52aa813ef82',
    PRODUCT: 'EOS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'EOS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '226754f3c81aeab151b799e8cb8d038c',
    PRODUCT: 'MKR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cab5cd8993cc1fe034fd2dc4aa3e0500',
    PRODUCT: 'KEEP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KEEP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '079d719fdc52624e151b0fbc182c0d2b',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0ed0b5f72164db4e8d8f2a8e27fa509',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b1ea6fd935f5acce104af73ddc9b2695',
    PRODUCT: 'CHZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f6380cb19d528c80c187e48c1381bdd5',
    PRODUCT: 'SOL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '886ac0cb744a6801cb437a9e9498eaf1',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '087321ccf8e71d7ec3e0fb82af50fb1a',
    PRODUCT: 'CHZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eb54e6f2282ede5ee5189fcf096a3df3',
    PRODUCT: 'KEEP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KEEP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '60888699de0a86effcfd7edd4f1feb7b',
    PRODUCT: 'CHZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '55ee4e9b8d539ad0aaafab7e39af1121',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1cf07a49041d6361c9ec057e509ddead',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a2f6e911f0bcedc0f26ab2f3d5e7fff4',
    PRODUCT: 'SOL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd027335fc6621d3fc5cf66ec1001cd86',
    PRODUCT: 'CHZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CHZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5db9474ff3c38dca09a2a42ceed39dda',
    PRODUCT: 'ERN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '024821642ce0601637c8adfcd52ce557',
    PRODUCT: 'APE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3030d9d2a3f8dda98977e0bb114be6ca',
    PRODUCT: 'APE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b70e4d86164fc6ccca8d4cb693b8f6c',
    PRODUCT: 'APE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1c8200111c9af17b974a74d23f089a20',
    PRODUCT: 'BTCAUCTION Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTCAUCTION',
    COVERAGE_TO: '2022-05-26',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c16d2dd72064345c39e2b2b415154088',
    PRODUCT: 'ERN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '174b7b16f71665deb58f00e252da8ed4',
    PRODUCT: 'SYN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SYN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8468154ce31614b9c124ad56e7c2143a',
    PRODUCT: 'APE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '35ad09aa39d065edc789f031a4760615',
    PRODUCT: 'APE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '793c6e84c97324453bfcc132ebcd8a7a',
    PRODUCT: 'APE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '007298f09210f3b8f6192c7bf6f136c1',
    PRODUCT: 'SYN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SYN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ec62f7d2e636cdc92a634c2f36399881',
    PRODUCT: 'APE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5abb783b855821e011d7b3385c32cb0e',
    PRODUCT: 'APE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdd71de0bb243ea29ee1968dee63f236',
    PRODUCT: 'APE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f20f302d1ee4333a3e5988f1486ba54',
    PRODUCT: 'BTCAUCTION OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTCAUCTION',
    COVERAGE_TO: '2022-05-26',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ed307281949c166c663eb50b6b442e4e',
    PRODUCT: 'APE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f51ae2d877ebf36a201b81a3aadcf4f0',
    PRODUCT: 'APE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'APE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c09b6d21f5bf9621bc76cf6663d5dd9b',
    PRODUCT: 'BTCAUCTION Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTCAUCTION',
    COVERAGE_TO: '2022-05-26',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e282bfc166c3c7e42dafdc4901b6196b',
    PRODUCT: 'MATIC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c2a312e888701977486da186ef42babf',
    PRODUCT: 'SKL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8449b395e308527c6cc05234ca9164fc',
    PRODUCT: 'SUSHI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'be9443c655de3158601955b4f7b4073c',
    PRODUCT: 'SKL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0810880dcfbd44f3ddc0dacd06831858',
    PRODUCT: 'MATIC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84e418d9b74a38d796c7fec80e74c9a1',
    PRODUCT: 'SUSHI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a0adabd8fa6a308bd5331aa42f557ab8',
    PRODUCT: 'SUSHI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cefd5f50fe9ecd5c5bab5c7bae6eff1c',
    PRODUCT: 'SKL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '368e285fbf5386eabab4c697329d1825',
    PRODUCT: 'MATIC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a51bd1958286f9c3e8572b6dab688a68',
    PRODUCT: 'SUSHI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ecaf0d8cb6b8f48131160e3b6cf0f02f',
    PRODUCT: 'SUSHI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '940546945b00ce379a823d883f7a005a',
    PRODUCT: 'SUSHI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ace1b4a899df9fd4a1e9136e6f17bdd9',
    PRODUCT: 'SUSHI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a664965249d5e3b3467b1daa8994c23a',
    PRODUCT: 'SUSHI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SUSHI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'baf7cf1c7c6beeabb27eea198aa033fb',
    PRODUCT: 'SKL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SKL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-03-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e88826b48af9e055ef7ce3e20cdc1dc5',
    PRODUCT: 'COTI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COTI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd8a1bacfa814ab0b96caef551e57e295',
    PRODUCT: 'RAD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c1cba145eb88c48549f7c68782230e55',
    PRODUCT: 'RAD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ed439ec562e2cc01a95ec8ac891c56d6',
    PRODUCT: 'RAD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bedfbcb80cd64f068b7d6932afcf51e0',
    PRODUCT: 'RAD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c61607a0ef4ec27f7749c1909d0da492',
    PRODUCT: 'COTI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COTI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef1d59e32101821a07fa9a4bcefeedee',
    PRODUCT: 'DDX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DDX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd376a74e8aee0268469abc2698906c5f',
    PRODUCT: 'RAD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '166ed4c8ee52a2da0481ad0843251d83',
    PRODUCT: 'YFII OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFII',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9575b7029c4f8aba22a03b601ba2ccf2',
    PRODUCT: 'COTI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COTI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f5a426d1a496ab748f0cc7f699578b39',
    PRODUCT: 'YFII OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFII',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '717d24bbbc5b3cbb542032566bacd1fd',
    PRODUCT: 'RAD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'afefff9ed019872f23f01d995c4dce51',
    PRODUCT: 'RAD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e766e8009b0415c12b3e98f03c05af77',
    PRODUCT: 'COTI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COTI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-26',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '787f01d976bf89168ce947f464f27ac1',
    PRODUCT: 'RAD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f8d89f29a70cf142cdb96199da06748',
    PRODUCT: 'UMA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b63526fa38741805b200b79c73d8ce30',
    PRODUCT: 'UMA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a74814f1566e7f14b618cd370e04c9ba',
    PRODUCT: 'LRC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd29e4c9250129d166e7b92c95f42bdf3',
    PRODUCT: 'YFI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bbb199f73d516593a9731e1ef2d07d69',
    PRODUCT: 'UNI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c505238af79a5d975c81d5f77d350f90',
    PRODUCT: 'UMA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'UMA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4172ab08dbe3cd2c736e478b069fb243',
    PRODUCT: 'YFI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '037d0eaf53cb560324daa312c4138da3',
    PRODUCT: 'LRC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd0597cafe4503815ee403ab00c79df77',
    PRODUCT: 'UNI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '28a4c991b73e5da730d2aa90ab7a1ca0',
    PRODUCT: 'LRC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '94a4cc77408cf50a07922d0bfcead765',
    PRODUCT: 'YFI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '52a7c69bc1aff61537fe8ff9b7138819',
    PRODUCT: 'UNI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5eebb9977798a9862342fb4d83be65bc',
    PRODUCT: 'YFI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'YFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1043664134af6e907def95be24fe4bfb',
    PRODUCT: 'LRC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6de7708f5efa82ed74e8486d0ed405e6',
    PRODUCT: 'UNI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5c14a175ffb94b84520cfa73e568a84',
    PRODUCT: 'ALCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'edc71ae8173f9552459063b0ce489293',
    PRODUCT: 'POWR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6e5c05044d5efe8acde0a6636b67d953',
    PRODUCT: 'POWR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f2b6a6525bd2377932e87a99f6eb6afc',
    PRODUCT: 'GALA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5e4a9694e5c6fb700d3d3da86a8bbf87',
    PRODUCT: 'ENS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1d7dbfce564c24068e8273c2d7842190',
    PRODUCT: 'POWR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8c283015fda78aaff66325389ea17705',
    PRODUCT: 'POWR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f05c8ad12b80000f9570bb8f72c7446',
    PRODUCT: 'ENS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a427bf1e6e128540007dd33b3faecae1',
    PRODUCT: 'GALA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fbf00d65d7db01c8989cb58f944db7de',
    PRODUCT: 'POWR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0e879fd29e45e2bb6e34cee8c0af4f0e',
    PRODUCT: 'GALA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '78dc053007929dc05c0542ab5867e4a4',
    PRODUCT: 'ENS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '48538b796865ff569dd5175802bb642c',
    PRODUCT: 'ALCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11ce643230ffee4a7cd77901541f5e24',
    PRODUCT: 'POWR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e516ceb8223983975107c4ed5b1cf21d',
    PRODUCT: 'ENS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '29695cdc7851579ef48f1c48761d6719',
    PRODUCT: 'GALA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e33a719bce281d6d94412ccfeb7ba574',
    PRODUCT: 'ENS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '28bc09c3b5527fba3d8a94c041573859',
    PRODUCT: 'POWR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdc6f81bbb2121c7b5392d0275ad02bc',
    PRODUCT: 'ALCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b482ebc0a4b933aff6ba3ef4a50f38ff',
    PRODUCT: 'POWR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7a6b27ea53f2c126cb7eeabd51438661',
    PRODUCT: 'ALCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3aec8da01c860c4c0db672cf6f07cd45',
    PRODUCT: 'ALCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee4e21c4a3a23bd7865f5dd8b51112df',
    PRODUCT: 'GALA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '19bc1122b0ce00dc3d2f749c966344b3',
    PRODUCT: 'DAI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-04-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e87f25b3244b7c07dd93695ddab2db26',
    PRODUCT: 'OMG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '21e5ef972c5a18eb330c5f41c8aeba8a',
    PRODUCT: 'OMG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef73f0b5d5bd41a443ff3d0eb66ff4f3',
    PRODUCT: 'DAI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-04-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8f9bb85ad0cd2182ee30f2028d964280',
    PRODUCT: 'OMG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c0eb6e59e8fb26ff76402a1eba74f153',
    PRODUCT: 'OMG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85e263a223894947ad319e955396a2b7',
    PRODUCT: 'KNC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '671aa113cc98219ebe3802cb8ca63777',
    PRODUCT: 'DAI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-04-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b3b5df711b2e5a8c9d686a25efb2f2c5',
    PRODUCT: 'OMG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f52af35847aca3c15f95761df87f978d',
    PRODUCT: 'OMG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c9d81684d233484a12d8501d5dbff5bf',
    PRODUCT: 'KNC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '34a57a14ca3ed5b394948a7092d062ab',
    PRODUCT: 'KNC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KNC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-02-25',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b90c9af9c8bc9c4e990fec2c731ee9ec',
    PRODUCT: 'DAI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-04-30',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f6a54f7d8e7e5237eda1040adeae7ee4',
    PRODUCT: 'OMG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '935296a8cf0765c387b0eb55e31fc204',
    PRODUCT: 'OMG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'OMG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-05-19',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '978a4363d9b259091d135367ce4419e3',
    PRODUCT: 'TRAC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0441adccc5e3d215c6cfc6839dcd82c8',
    PRODUCT: 'TRAC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '812a95ecf929cac7217310c608636917',
    PRODUCT: 'GYEN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GYEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5966cf17e33e1ef729b62dd251df8fc',
    PRODUCT: 'FARM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'FARM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'df4d9304eab63f49e7da0e6781190014',
    PRODUCT: 'TRAC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7bc69d301d39899e8e725e4da15d417d',
    PRODUCT: 'TRAC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e1ae62e7f6a433b876a48a7ce44734b8',
    PRODUCT: 'GYEN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GYEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c22f55a3d97c70f8af4a40f32548c846',
    PRODUCT: 'TRAC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f4137479d5127f5dddebac1c0d30969c',
    PRODUCT: 'TRAC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93bb38ed521048986a846f0d9b2929c6',
    PRODUCT: 'GYEN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GYEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2ed9bb901c738547d31783d0ce05e33d',
    PRODUCT: 'TRAC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3b03f7c0ab73088feacebf9ec1d2684c',
    PRODUCT: 'TRAC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3757da584f25ff360ba5f44c4b55af16',
    PRODUCT: 'GYEN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GYEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4d1e4a41738a04456d759a53922cc55d',
    PRODUCT: 'FIL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b86f21f1f5005e690f38c6b8236f49da',
    PRODUCT: 'FIL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7f7e4ca217d4393bc2b48edc30cf48ba',
    PRODUCT: 'BNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b4a9b846db26e2a10c0b86eaa1f011cb',
    PRODUCT: 'BNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '671b5a00ef99fb8cbaacea86b32dcbf4',
    PRODUCT: 'AAVE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e91216543cf54af48e36585c9db312a7',
    PRODUCT: 'SNX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '720d070fa60c630be7f6b18770104ed3',
    PRODUCT: 'SNX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '44c99e3d93419d2c127df96eaf345aee',
    PRODUCT: 'SNX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '16a3fe82bd279c3b462d5e4ff7d714e5',
    PRODUCT: 'FIL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e6722355548b10aab87c76f74d1db7f',
    PRODUCT: 'FIL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93486f9e4dc80f914e71bbde86efa226',
    PRODUCT: 'AAVE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '78d1332b5a0922541a1c09b0d2e344f0',
    PRODUCT: 'BNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7455b819dbc8b54f391724f627685b1d',
    PRODUCT: 'SNX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd91f815e5c8e528dc871e252ae2aa9e8',
    PRODUCT: 'SNX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6327d0e42cce4d3fa998a425344613ce',
    PRODUCT: 'FIL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '067524f0a8d336b1d1d23f85e52c2698',
    PRODUCT: 'FIL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '82cb801dcb2e80b4b28752cee7422d58',
    PRODUCT: 'AAVE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '47fd1fda68523eb444fdfc004dbc91ed',
    PRODUCT: 'SNX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b22f5f5fdae831eeab7ca99ba77be092',
    PRODUCT: 'SNX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a68e804ad288ebeb5ad22367cd513afd',
    PRODUCT: 'SNX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '99c48d09f67c8916d35d327a2a69ad08',
    PRODUCT: 'AAVE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bec9967999d53a62b9994d703e9889d1',
    PRODUCT: 'BNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e666731147f915e31e929746de73ba2',
    PRODUCT: 'SNX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '303ee5ee627aef372ca10df9c24e59b3',
    PRODUCT: 'LRC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a724d2b5d21663b25f4b41e93cb14271',
    PRODUCT: 'FIL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FIL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '93e67b6f392105fe68e980e7ad38169c',
    PRODUCT: 'SNX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7357fedac0c1b35cf1621a6d7a40f694',
    PRODUCT: 'AAVE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7cb7615659fe570db9e81d0cde814f95',
    PRODUCT: 'BNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd4707bec62de88cc0aac1afad8283b8c',
    PRODUCT: 'AAVE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'AAVE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0f2dc578e3e62c8042fc3e349f0838f',
    PRODUCT: 'LQTY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '89c9b704ccc3c500b28ba2eb971a1b9b',
    PRODUCT: 'PRO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2d438d755bd6f68f318e5e17c78115dc',
    PRODUCT: 'LQTY Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da630b01909ba9f4c71dca10e96d9428',
    PRODUCT: 'GFI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6f04460c1864bac4caf3736ec33bbe5b',
    PRODUCT: 'LQTY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fa3253370a49e94337d8e60308902135',
    PRODUCT: 'NCT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a5812d15d8806fc138f8d3101cd02448',
    PRODUCT: 'INV OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'INV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1e459863e2d6549822d4a4a16095e64f',
    PRODUCT: 'NCT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4ec657b64af83e3d056092399f940be6',
    PRODUCT: 'LQTY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1208920634230b9af02d69e66a27b6eb',
    PRODUCT: 'NCT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08173bb522726d016291585b92e26b6d',
    PRODUCT: 'NCT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1b2cfd4d1d32bc10c9b9bdbf89a00a90',
    PRODUCT: 'PRO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c79a33e40c4e1c5ec541df32e02097ca',
    PRODUCT: 'NCT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e2f67ff118147def07ed72a231438aaa',
    PRODUCT: 'LQTY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e63819f77d02f377d5f1f39e1fb7f0d4',
    PRODUCT: 'INV OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'INV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c209db8f2ec3c569438cc7ebccd9b19b',
    PRODUCT: 'NCT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '756a6ef38b639fc703eeabbce05b4f3b',
    PRODUCT: 'GFI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GFI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-11',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a62284b67dd3f7b655582f939cd1d65',
    PRODUCT: 'INV Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'INV',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8cc9938189baef1e7353b8c7d3661102',
    PRODUCT: 'NCT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'NCT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd0e0527969b782b4cce3a866d9f66f6e',
    PRODUCT: 'LQTY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3a794a4ca9d461b8d2c3b212a9c02e0d',
    PRODUCT: 'LQTY Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LQTY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-01-13',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aaec4c52246103338e953fde898612e0',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0d47c54efe91947a08b3c3f34dfc2814',
    PRODUCT: 'MATIC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '693d0f3229e64d61d476dbd6cacbb7a0',
    PRODUCT: 'ATOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03a9c97ad3df3c864143213b246a83ec',
    PRODUCT: 'ATOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c1393cf65d4bec8566c96e91e05ce934',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b82ab9d99d3ac3d4c194d7fe90427a14',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9c1a6cb6d51b8536bfd4021e564cbef2',
    PRODUCT: 'UPI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e3c3e05a09f419357baf2dd485da5e14',
    PRODUCT: 'MATIC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fbb44be100fb34ba3d148afae4ad4c8f',
    PRODUCT: 'ATOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ad657f6ef6921fb0371dfc64e8df62f4',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85fad58b206251b55605cd383da480f9',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb686eafb22d752d7a5430de7f608996',
    PRODUCT: 'UPI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'UPI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5aff2a7abb02d5c4ebe740c1e9aac8b8',
    PRODUCT: 'MATIC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MATIC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '05cb6dd80fdcb89e2bc635fd39913467',
    PRODUCT: 'ATOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '508cfbc1a37b25fcc405656a2952cba1',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f06e9e09266041e6a3e5c8e25d1071c8',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-02-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '48ceed22bb8eca2d6650f1488d17a8aa',
    PRODUCT: 'DOGE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '097edb782666fc8a5e6601e7f2fbc96b',
    PRODUCT: 'DOGE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cf648380be3f2eb8027ce99bf6900d55',
    PRODUCT: 'DOGE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f018f6bf882d42b7ca34dff4e03848a0',
    PRODUCT: 'DOGE Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a596eaea4aa96dba1f1388b625ee1d4',
    PRODUCT: 'DOGE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7bf3c7f6eb6f1f323fc28a1588f5566',
    PRODUCT: 'DOGE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5aa7595c6ed411d468aea3ec98911ac3',
    PRODUCT: 'DOGE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dd499b4eb448f5cef5783eed04f4bfa9',
    PRODUCT: 'DOGE OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '463a7e21cab0f839177aef4642e14470',
    PRODUCT: 'DOGE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3768dcb03055b6c64ac8e6e4d5c62eb7',
    PRODUCT: 'DOGE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '46dd16cc6b90ce2441bbc2e729cee5b8',
    PRODUCT: 'DOGE OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9563b6c458687cdc8ed8cb4159bfebd2',
    PRODUCT: 'DOGE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4af89f1a7f4ef2c431e41fdbba4b7c12',
    PRODUCT: 'DOGE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b94e78604a758e81737eb32c89c9a357',
    PRODUCT: 'DOGE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '99d141211371f319dafc3210a06c6020',
    PRODUCT: 'DOGE Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOGE',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c5f428e0cd644b9fec1790b1d707aecd',
    PRODUCT: 'BAT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fede0325d94781dd51cdf14af72982fc',
    PRODUCT: 'ZEC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3016cae46025463a4b1b8d54531f6e56',
    PRODUCT: 'ETH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'DAI',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a9ce70694f79abbcf2ff431d80847c31',
    PRODUCT: 'MKR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'MKR',
    COVERAGE_TO: '2019-05-09',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-04-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e174b8cf413eef00b5562ce227875511',
    PRODUCT: 'BAT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c1ea44359ba6543ca9ea296653a6b4b3',
    PRODUCT: 'ZEC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72785eb7371be613a657e879fcec3020',
    PRODUCT: 'ETH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'DAI',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b588af45a96516ba813f470843e7c97b',
    PRODUCT: 'ZEC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '092bbbcf0924cdd8200d7ffd3240ab4b',
    PRODUCT: 'BAT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a39a48fe11ca405cec1c3a078154436',
    PRODUCT: 'ETH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'DAI',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8120123484cc13ec8f3c0b29a6e5e904',
    PRODUCT: 'ZEC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ZEC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b973d6c0f5055ca9d0a5fff3f708027d',
    PRODUCT: 'BAT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cde305d4daa0c36084cf859963ab9326',
    PRODUCT: 'ETH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'DAI',
    SOURCE: 'Coinbase',
    BASE: 'ETH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2019-05-29',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c0a5021a47659bbfef7732de1e261217',
    PRODUCT: 'BCH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7a4ed5674e8f09d8756e92e25bc13cb9',
    PRODUCT: 'ETC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5267f3dba8e3c34ceb1b3922f795fb2f',
    PRODUCT: 'BCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '365b69c6b60949e9532b8747490accb2',
    PRODUCT: 'BCH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f052cb0d1240e020f5c89f829808c289',
    PRODUCT: 'ETC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6977fc7411ad140dc4c2adf79f810e70',
    PRODUCT: 'ETC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f8a200f484c32b87fa23d15d2207f33',
    PRODUCT: 'BCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-17',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bcb13cdfe54e4f32f1384332da199302',
    PRODUCT: 'BCH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aff552c558bc86c029a29093e7366de1',
    PRODUCT: 'ETC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd0437541e5e09666e66810fbe8b93650',
    PRODUCT: 'BCH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BCH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-01-24',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '914484cebdd7b836af5559df29f0b1ce',
    PRODUCT: 'ETC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '24261fbe5a84c9374af55a2976e39f43',
    PRODUCT: 'ETC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ETC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2018-08-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '820580f711a13e06dc3f3fbf88e69daa',
    PRODUCT: 'GODS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GODS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a9c632137689e3c79e91e1dab737c28',
    PRODUCT: 'API3 Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b41979af14e2c5d69da7b17cd70da3ad',
    PRODUCT: 'API3 Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f88ca1d651a7b4e01d39564f1155ba7e',
    PRODUCT: 'BLZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BLZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e5ab27a20f51b077995a9bf26114f40',
    PRODUCT: 'MDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7368ef9c2dac0d74bc2850acc6a157a3',
    PRODUCT: 'API3 OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b8aec19b63b0f134e3ea2eed33405bab',
    PRODUCT: 'API3 OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '34602740642e0bd46c8193cdfc953ea3',
    PRODUCT: 'BLZ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BLZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd1a8bda567a9fa6bdd9162485cce95a2',
    PRODUCT: 'IMX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2fd20c0a193c0ac68e360d772ac53959',
    PRODUCT: 'RBN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RBN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'be63bbc7fbf02f2a997e5f595eeba8cb',
    PRODUCT: 'MDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bfd80699aff6c7ba7ba5a4efafce4a7f',
    PRODUCT: 'API3 OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '08a1f1b1f1a710849da003a42b8fdd63',
    PRODUCT: 'API3 OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'accf2ff4841cdc6797915ed5e52c3308',
    PRODUCT: 'MDT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee8bfecef3374a69589bcd441f510077',
    PRODUCT: 'API3 Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4f0eaaf46617f9d95ef8e184fd8871a9',
    PRODUCT: 'GODS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GODS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c7fdbd1830669dd59b88baccbaa3d272',
    PRODUCT: 'CRO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c6075cabc724f4952e46bf02f570efc5',
    PRODUCT: 'SHIB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f5152c7734fc06ea5806983ece3b5e58',
    PRODUCT: 'SHIB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a558f42cede3d68d591566b8b0d282d5',
    PRODUCT: 'CVC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1dd836eefef98d9f0ecaae722b2f6c4c',
    PRODUCT: 'KRL Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '921d7d6bc1cab0f7058334515cdb3573',
    PRODUCT: 'DNT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '773658cae97e519cc0d28c1217dbd15d',
    PRODUCT: 'SHIB OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7c64b9748e0be8cf4867546830428285',
    PRODUCT: 'UNI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '270b756b8cf2728548e5bad6fe7d2539',
    PRODUCT: 'LOOM OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84ef0b3a307001718dff8ee7e4b57f57',
    PRODUCT: 'KRL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f7216a7ead66b09c855981037c04aef3',
    PRODUCT: 'KRL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '86e3fc74c4dfedcc5a9970a8a565302c',
    PRODUCT: 'UNI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '55d9ed0f9a037f90ead7c07910a2d5f1',
    PRODUCT: 'CRO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '870d29ec7e8b51556746309127470714',
    PRODUCT: 'SOL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'SOL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6e5012695c4c5dcc407714262a4f6766',
    PRODUCT: 'UNI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '42fcea83a43d9a5ebc3b5b66df1e3a32',
    PRODUCT: 'KRL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f2e5d8ea294accd5be0917440eb77b4a',
    PRODUCT: 'CVC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CVC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '99a82a254f115e7a2f9314659c0be3ef',
    PRODUCT: 'ATOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '73ee1dc70a001b3f6dc4baf29bd2fa72',
    PRODUCT: 'LOOM Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2355bf869ea070d2886344b8b274aa37',
    PRODUCT: 'SHIB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'SHIB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e04dda480cbf73518c9ad4060dbf1e23',
    PRODUCT: 'REN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '575c4a124e90a735d2c4fbb19f5b46e6',
    PRODUCT: 'WBTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a8a5b0e7ec414ad5bb90c5387b2c0114',
    PRODUCT: 'WBTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a17a62dba2162711e70cb64ba94baf20',
    PRODUCT: 'NU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8eabb40d490ee390c64cdbfbaaaea3f3',
    PRODUCT: 'BAL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bb488b4f1c0d978ea743ebe08ccac4ed',
    PRODUCT: 'REN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b892d35db50ba97c1763b698cef815a1',
    PRODUCT: 'WBTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5391ec5cd6261fcc83c659cb09db0861',
    PRODUCT: 'WBTC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'beb40a0fcac194055ca83bbb18b95ce1',
    PRODUCT: 'NU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2e05858336cf93302dbb24e29a19c101',
    PRODUCT: 'NU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6a88f4d7af662eca338855da22500701',
    PRODUCT: 'NU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b3f42cbb6fd200efc99199825aaead6',
    PRODUCT: 'NU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc757d0287a42251cac1c696b4569f19',
    PRODUCT: 'BAL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50ab43e8908f52a16c01282f47962ac8',
    PRODUCT: 'REN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a2b548c88f22bbf88fd05e4280dd672e',
    PRODUCT: 'WBTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bbf3ed0fa83dd4c18f9b094059016cca',
    PRODUCT: 'WBTC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '919d1e2026c221b602e4b8093bb09a2a',
    PRODUCT: 'NU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c2296eb3ac383855bb886021184cdf33',
    PRODUCT: 'NU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7ad410a48ab5acf621880cad4d121128',
    PRODUCT: 'NU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e626ee3c15308089702d3babdd09e36',
    PRODUCT: 'NU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9ea3001994517b71b402d65ae73c26b2',
    PRODUCT: 'REN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'REN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '72c8d47a0bf3ae474e4a4db2e401b911',
    PRODUCT: 'BAL Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BAL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2ed4a610600a75cb400e3647c2493e73',
    PRODUCT: 'WBTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9586d2009a766907829e9515d7eff0a8',
    PRODUCT: 'WBTC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WBTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-10-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ed6717d578197199c63e5ce2e3841a8c',
    PRODUCT: 'NU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0d38d0eb1e1ca094d6236ad4f910d175',
    PRODUCT: 'NU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ff21fae858ef68085f8b0a4bb9d8fb95',
    PRODUCT: 'MDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '78abb933e42d0ad866c4d45737ddbf8f',
    PRODUCT: 'MDT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88394a8b52e82278f9424849579b6b8c',
    PRODUCT: 'IMX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91a0124541a75871e8541ef804d3fade',
    PRODUCT: 'RBN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RBN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '42b1f472c32ce165cc20c2f01a1803d0',
    PRODUCT: 'MDT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a52955a06985580387c48f972c638304',
    PRODUCT: 'IMX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e7ae5d8ff0f85234b1ca6d0828cbfd4a',
    PRODUCT: 'GODS OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GODS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f33e3b919f7fe2a2b1f114675ca501d9',
    PRODUCT: 'RBN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RBN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '45429a0cccce1deac8435ea649eb4fa5',
    PRODUCT: 'IMX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6997971308a9be9b96ebbcc809b27425',
    PRODUCT: 'GODS OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GODS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fc3ea176288bb43b78108e576c5be336',
    PRODUCT: 'IMX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3138de4dd25d7e63b9b2d39b9b6b33a4',
    PRODUCT: 'BLZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BLZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9812b1891902a7afbbe92529ed5545c0',
    PRODUCT: 'RBN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RBN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc75b0e3267d890257db205cf4eb98c7',
    PRODUCT: 'MDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '55f10d273e8323effee5bc1a8143d3d9',
    PRODUCT: 'IMX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'IMX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8de4f9dfcd95b45206267bbad1c669cb',
    PRODUCT: 'MDT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MDT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4f88a2d133efb3eeeafdf02ec3547b6f',
    PRODUCT: 'API3 Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'API3',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-09',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fc3cf8aa37299a817bac4f5b648e10ce',
    PRODUCT: 'MIR Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf132458ff4d37e6a265d70d427cc434',
    PRODUCT: 'TRB Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '373e0dc81223afc48aeee159fe23285b',
    PRODUCT: 'CTSI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '31c94d0554835d61751af2ac9f541d1d',
    PRODUCT: 'CTSI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c67861e9de8a3695a2485bfda212cc6b',
    PRODUCT: 'CTSI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '022c65381d8169ed3eb87d5d19a3eb2b',
    PRODUCT: 'MIR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '627ff3f51edb31b080f9a2f853485223',
    PRODUCT: 'MIR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'feba206bc0e174e239f3825fb8711ce4',
    PRODUCT: 'RLC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cf77cd40e79ec038a35c488378b05b75',
    PRODUCT: 'MIR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2fc3850fb164ff4c70d38f9f876e93d2',
    PRODUCT: 'MIR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7a4b4ed18650d4596b5c94a278f7ec4d',
    PRODUCT: 'TRB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bd102765798321269c23047415fb43ff',
    PRODUCT: 'RLC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7772001f1714c32eec9664746338fc95',
    PRODUCT: 'CTSI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cc53218778f85b446d372b15d68f0efb',
    PRODUCT: 'TRB OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '069f9e36ccab64d7fd7ca5f2e0928001',
    PRODUCT: 'CTSI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CTSI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b61af7ea028fd256f7b926aafa01b81f',
    PRODUCT: 'TRB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '460bee7b64c6ccfbb861c2ed1b86933c',
    PRODUCT: 'RLC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0853167d5240f6b30ddd38feaff30314',
    PRODUCT: 'MIR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a42f4e335f701007a18546ba8c59c84d',
    PRODUCT: 'MIR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4b9b59ffc26a1defeadc4483b82ee201',
    PRODUCT: 'MIR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MIR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f4fdcfb09fe0af9296b5f50aae1e0b0b',
    PRODUCT: 'RLC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RLC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '65ca012982e0a7a0804d77e9ca7d4049',
    PRODUCT: 'TRB Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRB',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-05-06',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd15876708a90d03964fcd6344282a6ba',
    PRODUCT: 'BICO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5af1cc22d4c052bd4206de90f506a2bb',
    PRODUCT: 'BICO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c05e023a040aa4b7f0eb8a75d104135c',
    PRODUCT: 'BICO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '98f434fe9d408fbd30cb53c7b8cb3f06',
    PRODUCT: 'VGX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9451a12e972e6b1d083be6e838229c66',
    PRODUCT: 'BICO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88bf8d4cf041401cf9f97d50c2486319',
    PRODUCT: 'BICO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '561f0a47a940dd3f4dfb2aaef94b62ed',
    PRODUCT: 'BICO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '70520c930f4ec57434c7a3b72ec86235',
    PRODUCT: 'VGX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '838f303809bd3db691afa9b336ad4b09',
    PRODUCT: 'VGX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'VGX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5c4f0050f1a9afc793734a0b16c0f1bd',
    PRODUCT: 'MUSD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MUSD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bf5eb824567fab37c9e1193a7b4fea72',
    PRODUCT: 'BICO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5a18e1d3be1bc779c6443c7530531ba5',
    PRODUCT: 'BICO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b7f530b3cb8c1ee8b6d5fd8217e9a260',
    PRODUCT: 'BICO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b14504bf843693af7ed1d3b183785b4d',
    PRODUCT: 'BICO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '100a33bcefd636325821f2e941618276',
    PRODUCT: 'BICO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f3f9db3e1593ff53e54dbcee4d140c6b',
    PRODUCT: 'BICO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BICO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-12-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3cf9ea1712940ea9aa8dee74bc81dcd4',
    PRODUCT: 'GTC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'GTC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd08f238898cfb9d40d02d7b7dd7b1a5d',
    PRODUCT: 'DOT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a9eccc9b8e0c2757c6b37a2d9c677e85',
    PRODUCT: 'MLN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MLN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6f2fc7d55d2d8bbd30039e4caa2be63e',
    PRODUCT: 'DOT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae9ca229683c0081d9e720bfacfa895d',
    PRODUCT: 'DOT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'eacf22a2130133f0ff3cdb303ae515d4',
    PRODUCT: 'DOT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '959aff7fde11fa1962602f02f4d301ca',
    PRODUCT: 'DOT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6aa3204fb7da3e8212c37e2f56395e5b',
    PRODUCT: 'AMP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ea1b19a1270326dba0a680a93591d51f',
    PRODUCT: 'DOT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '4eaacedb62b563fd25d49e182c19b530',
    PRODUCT: 'DOT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3968ce56b4af7e2edcbfcb28b82c7371',
    PRODUCT: 'MLN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'MLN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-10',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0c74f91b10e1e6a27a6fcf95fdfdd0f4',
    PRODUCT: 'DOT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0a73305d43f12434608f188ae449fd57',
    PRODUCT: 'DOT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'DOT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-06-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '79dfaa18a112e194828822b562b7b739',
    PRODUCT: 'WCFG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9164bb3519b3b425c3c1e2ee4b851306',
    PRODUCT: 'WCFG Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3aae527e2c19434209b8b897a68337da',
    PRODUCT: 'NKN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f028c7a946bf6627f1cba0ec61ed211c',
    PRODUCT: 'JASMY OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'JASMY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '278f642eccb26028f7de2a96238f3fda',
    PRODUCT: 'WCFG OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'aee7370b5314997c5ef264418759929c',
    PRODUCT: 'WCFG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '602f501838d67493f94361e182f9bc74',
    PRODUCT: 'WCFG OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '85ee0681f9f4760598551230c2505a47',
    PRODUCT: 'JASMY OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'JASMY',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'be80efc4e7986129b1e6a4add52ed994',
    PRODUCT: 'NKN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NKN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-12',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '25c3b5101d7bf697818a4fbd7df8c4f5',
    PRODUCT: 'WCFG Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'WCFG',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-10-07',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '56ad0494e7addfef2c5d3daafe033e7d',
    PRODUCT: 'CGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c2d131bf1208b21178d631400425e9b7',
    PRODUCT: 'CGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'daa8d7c4faba7ff6f12cf3e1ed56b85b',
    PRODUCT: 'CGLD Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3f1cd62760260aa5b6bbb609a2b8eab0',
    PRODUCT: 'NMR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '96faa37c2a59abb7887d206d41ff44f8',
    PRODUCT: 'NMR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e93ef4c6ab52dbdd41695f2ab075247b',
    PRODUCT: 'CGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bfbc25c9a8f54628549e6d82c385ff92',
    PRODUCT: 'CGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c84eff143dacee1dd280e8e01d8a382b',
    PRODUCT: 'CGLD OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b4662b3dfbe54a6c380df6116c56b1c2',
    PRODUCT: 'NMR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ce2945faed549cedff2772a8a021bcb9',
    PRODUCT: 'NMR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '07bbc35238887062df0c1a950cab41a5',
    PRODUCT: 'CGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '88c6636bf6a896f36f558cc732bd5cc8',
    PRODUCT: 'CGLD OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '46450b15605b1cf43c53ffeaad7461e9',
    PRODUCT: 'NMR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1b15c313d208765758aff15d65354fd6',
    PRODUCT: 'NMR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '209cbc343088d33d0def257e36e5557e',
    PRODUCT: 'NMR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6d80c1752e4ef37f0d01530c9d83fb20',
    PRODUCT: 'NMR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'NMR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-08-18',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8e72e77648d984da99f4dd80dd2863cc',
    PRODUCT: 'CGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc1f27975bfeee1971e2c93e0102b7bf',
    PRODUCT: 'CGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '54b245e91cafea5d181b77a12e2941a8',
    PRODUCT: 'CGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bcb71d8a3fd980a418cb8fc1e7e451d6',
    PRODUCT: 'CGLD Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CGLD',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-09-01',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ee9fcf35538d8d67c05c5dd7686383c1',
    PRODUCT: 'USDC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bc64216f81093d958d8378cae8c465b1',
    PRODUCT: 'USDC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '804c68a9641717818d4237c71c7bfebc',
    PRODUCT: 'BAT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '948905cb08555da233f42a17ea6e8ddd',
    PRODUCT: 'ADA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7631d11fbfef7a461f3efa76cbfd805f',
    PRODUCT: 'ADA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd46e5feff15f28a28aeeb2411e4887bf',
    PRODUCT: 'BAT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '03e843b5478498e58026bd99623e8ad4',
    PRODUCT: 'ADA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0f6d438e4318e71f522ece0effa4173f',
    PRODUCT: 'MANA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6d3ea04b267488b3b94a34471d95d1ce',
    PRODUCT: 'BTRST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '35e14b44889da879153ff754071bbf4f',
    PRODUCT: 'BTRST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8a144784abd952eb0b46d09e3a523c8e',
    PRODUCT: 'BTRST Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b7606071158ffd42efef064eb384925c',
    PRODUCT: 'ZEN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '490754aee657e0920b644621971f6952',
    PRODUCT: 'BTRST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'd151ca8ce95f9bd8af795feb743b6239',
    PRODUCT: 'BTRST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c8166a1ecb09b0fc56de638fa1df265c',
    PRODUCT: 'BTRST OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2ab30d8e99b05450d8b60afc089e6a5a',
    PRODUCT: 'ZEN OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2fff37dc0fa610e4b2066427045565f7',
    PRODUCT: 'BTRST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '197244cb8865f10b859fab8984a81137',
    PRODUCT: 'BTRST OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'fb3806c83fdac43b60ba8415413ba279',
    PRODUCT: 'BTRST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb38e6bd2243be64ead2e5e13705b721',
    PRODUCT: 'BTRST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2c39fbbeb8bf6eb2124e2f8edebc8412',
    PRODUCT: 'BTRST Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BTRST',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5d6d60d121abb1d61fe9d4cade0f994f',
    PRODUCT: 'ZEN Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ZEN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-09-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5dae190a31dd6dbec5864972f943839d',
    PRODUCT: 'QNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5cc66bd3546787a9c207f362feb3090a',
    PRODUCT: 'XLM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8ceb3a6fdb38dd29926f6781dd8a344e',
    PRODUCT: 'ENJ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a948bc83e5b0501ba207bf7c074d7383',
    PRODUCT: 'HIGH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'HIGH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef58e03fb83f8b324cfff23d6f766355',
    PRODUCT: 'ERN Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dea4a3a4ef189e3f9e93b984efcff26b',
    PRODUCT: 'AIOZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '2647c8f45f0cd694133cc8c220f45917',
    PRODUCT: 'LRC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '50a85c7a6cbb928598c51248b69dc074',
    PRODUCT: 'ENJ OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '73a9ceb165f7bc3e1dd1888f075301ff',
    PRODUCT: 'ENJ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENJ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cb805660a0f56b7ef2bfddfd6fe82953',
    PRODUCT: 'XLM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'XLM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '441dc664daa4360a769458d86482aa94',
    PRODUCT: 'LRC OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '021182f0afcaf4717969f653328c95cf',
    PRODUCT: 'QNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '07acea65cdf8fd4a1163eddc063b5d81',
    PRODUCT: 'ERN OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ERN',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8cfd889515ab1a2c04528ce05cf7d4ef',
    PRODUCT: 'AIOZ OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'AIOZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ccb305c996e717cd59ef2b655691243a',
    PRODUCT: 'AERGO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'AERGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ac5164176a6785707fd48c7f9ede2fae',
    PRODUCT: 'QNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'QNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8920eae7b6534d8c0e7c82752007f9e4',
    PRODUCT: 'LRC Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'LRC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'af1da22161006826b88a90427ed5ce95',
    PRODUCT: 'SNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2022-03-08',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '02f233350b4222aa5279e0d93471add7',
    PRODUCT: 'SUKU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e48558a1eac099567ee90ae665c62e7d',
    PRODUCT: 'SUKU Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8d09a0cfb5fd7d7c5593ffa15f75de93',
    PRODUCT: 'UNI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'UNI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b29a2dfb8bca5b1f8a01a79bbe862bec',
    PRODUCT: 'DNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '94f48afc66c57523675b18965863b2e3',
    PRODUCT: 'LOOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '39cd128a07243dd12934f7ee6323e147',
    PRODUCT: 'ATOM Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '11ff8dc50f92a82ffb4d5f7ee0bdad28',
    PRODUCT: 'KRL OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7d0c873d5aa5ee16e6428aff93a3ad2a',
    PRODUCT: 'LCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '163b357168904be8736c5e7034efcb36',
    PRODUCT: 'LCX OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0627268cf7d5bab841b073aefa54c490',
    PRODUCT: 'SUKU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '37e84802905953d9fbf5ce7eb8b79b4e',
    PRODUCT: 'CRO OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a26f5d574ce4424839a62532d56b0250',
    PRODUCT: 'SUKU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b67820a34b7c1f64904c2f74c6456a9b',
    PRODUCT: 'KRL OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'KRL',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a3916a578fba6c314e571d33ee7410dd',
    PRODUCT: 'SUKU OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6b4e5eb7552dc6dbdcffc633602c2214',
    PRODUCT: 'LCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e292eb9aa1151b16b945820d8e05bb04',
    PRODUCT: 'ATOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ATOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c030ca7316d727ce73c678159c565887',
    PRODUCT: 'LOOM OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LOOM',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c3c34698d45eaa21453579503f2aba34',
    PRODUCT: 'DNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '34584e4431051ab9b5ba6e188ceca47c',
    PRODUCT: 'SUKU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dfa72439ee8f42252437b53ac049782f',
    PRODUCT: 'CRO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'CRO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ae3cf6ad5caaac88842c7c225543a2d2',
    PRODUCT: 'DNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'DNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f2c6558324e8e53beaf1a0320d3565bc',
    PRODUCT: 'SNX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'SNX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8aa5d9e5592514d935ebb1bdfd6bd4b9',
    PRODUCT: 'BNT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a6c45cedef8e75fe3dce4aa41ce09454',
    PRODUCT: 'BNT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6410c320d6384e2fc931b1f9cef89ecf',
    PRODUCT: 'BNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c8dbd76a9ac4fbb24fa23703dcf4441a',
    PRODUCT: 'BNT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BNT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-12-15',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6fda30ec3a208ddba17c8934c8dc8131',
    PRODUCT: 'COMP Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dbee71b5df793ed7e20c1f21d3f70113',
    PRODUCT: 'ALGO Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '43a04b1551b4215c3af23e554d1c9925',
    PRODUCT: 'XTZ Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84cb38e819ce6f60951059060cae638c',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5220e8d724a85a33c47af90dda85f3e6',
    PRODUCT: 'LINK Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '550f2b4a7a855e69abae7a7b9245d74d',
    PRODUCT: 'LINK OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b27cf851b95d79750cfe9b6a62e1af4d',
    PRODUCT: 'COMP OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'COMP',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-06-23',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3aba2baced64264fff7d596cb6bec042',
    PRODUCT: 'LINK OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b90cbfc763d3b6d24843818fcd0f9934',
    PRODUCT: 'ALGO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '61ed4f0b9fd4baa5bd5d760eb84cf401',
    PRODUCT: 'ALGO OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '04e5bd951e6e868d3299931072b49ab7',
    PRODUCT: 'LINK Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LINK',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5f6cd2ca861817ffbf99e6a5d7b21c97',
    PRODUCT: 'ALGO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ad5bfc6469ed551dbabb838f0a994395',
    PRODUCT: 'XTZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b79ed099fcf4c9ac40003571a54a06f7',
    PRODUCT: 'ALGO Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'ALGO',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8474d341ca6c4b93c50db1ce6bfbe496',
    PRODUCT: 'XTZ Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'XTZ',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2020-07-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5486ab10db1f396e1abc3432b927510e',
    PRODUCT: 'MANA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'cda68e59de113ee3ce362a7d1d70cbf5',
    PRODUCT: 'BAT Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'da33008e45c76241f5b60d58486db14d',
    PRODUCT: 'FORTH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ceda9302cdee62b66b2a23e7e9fc7356',
    PRODUCT: 'FORTH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5ec4499e078aa0985019ad54f3bb09c5',
    PRODUCT: 'BAT OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '659a2978c34d07e2c47384add25b4f29',
    PRODUCT: 'USDC OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'USDC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '54630bfd807cd7a5431acd6fd26f7545',
    PRODUCT: 'FORTH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '38c014019ab3b1ee6fabe32364b619c8',
    PRODUCT: 'FORTH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'bdbfea35a9da2493196b4b764fc2b16b',
    PRODUCT: 'MANA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '136d03c6f6e898ae68dd7aae4c48a107',
    PRODUCT: 'BAT OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '49ad0529df488e9b76cd2b17fe9787d5',
    PRODUCT: 'MANA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'ETH',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e64204d07600c0b3d879f55f9807d37f',
    PRODUCT: 'FORTH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '40e0208ef865f8ce2392643a1ba1007d',
    PRODUCT: 'FORTH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'GBP',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-05-31',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ba40fda815794e2eefb41cf36e963486',
    PRODUCT: 'FORTH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '7a311b8a3043a17afab70cd97ff35993',
    PRODUCT: 'MANA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'afa8fcde12ca9f5d5ce878dfebd0b2f3',
    PRODUCT: 'ADA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDC',
    SOURCE: 'Coinbase',
    BASE: 'ADA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6db29e192ad37a5f009a486ade04ac1d',
    PRODUCT: 'MANA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'MANA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '91907e78ee4a52b4f347bd6f9c45508e',
    PRODUCT: 'BAT Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'BAT',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-20',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '164e65e8ce4dbccc5290348755870dff',
    PRODUCT: 'FORTH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e94fde64408cfaddb5f651f979cac6c8',
    PRODUCT: 'FORTH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '16b3d9a72d831cce3f5892c34c39fe6a',
    PRODUCT: 'FORTH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'BTC',
    SOURCE: 'Coinbase',
    BASE: 'FORTH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-04-21',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'b3703d70fc126a3b7b7e8a00bdeeab55',
    PRODUCT: 'LCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '9b4bb415843da3842778d1b6db7e7424',
    PRODUCT: 'LCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '07bd523cc717ba915a2667c19d245d98',
    PRODUCT: 'TRAC Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'TRAC',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '3dc60a4ac76299fbba726f92134635a8',
    PRODUCT: 'SUKU OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a23ab1d4716214fd783d932b847a8219',
    PRODUCT: 'LCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'f6d9b1a29e1b82d8e0947060b122e66b',
    PRODUCT: 'SUKU Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'SUKU',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c80c6491111f523f7b13e72ceaaa3d0',
    PRODUCT: 'LCX Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'LCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-02',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6163972bb157da196791eafa09ee92d6',
    PRODUCT: 'ENS Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'af81f78b00b6a9351106e535bad4fa7e',
    PRODUCT: 'GALA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '1258ab68fa90754a9578a2bdfc3b014e',
    PRODUCT: 'ALCX Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8c2c96ad4727d9c2f6745e80a1dbd375',
    PRODUCT: 'GALA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '68b41f92842ed8103c02e8e906458496',
    PRODUCT: 'POWR OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c0490274f1dbcd9e524438e43b4576fb',
    PRODUCT: 'GALA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '0ffa258dfe027d1cb868baf15faf13cc',
    PRODUCT: 'GALA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'GALA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8fe05ff4fb1f478f791cbc129d72a38e',
    PRODUCT: 'POWR OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '51964205cd58d44e3cfa291d0b2b95cc',
    PRODUCT: 'ALCX OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ALCX',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c654c63ca4eefafc8359d24d7e93e29',
    PRODUCT: 'ENS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '55b15c66afe09bc51b0bc8d0cbaad373',
    PRODUCT: 'ENS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USDT',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ef3b485057b4fd6afdd40d14c1b6ca3f',
    PRODUCT: 'ENS Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ENS',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'ce80409f4efbdab87bd6390e982a05cf',
    PRODUCT: 'POWR Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'EUR',
    SOURCE: 'Coinbase',
    BASE: 'POWR',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-11-16',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'c688c2427ca9af4c016a0bd91bca5b2c',
    PRODUCT: 'RAI Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '558dacb118476101d2ceb1cfd9ab00a0',
    PRODUCT: 'ACH Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ACH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '15223a170d24727110d7322abc3d9523',
    PRODUCT: 'PLA Trade Data',
    PACKAGE: 'Trade Data',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'dc52270797ec3d5c124319d8349e4dd5',
    PRODUCT: 'RAI OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'e0472d137f83ff4c0eb35c9f43bdb9f2',
    PRODUCT: 'ACH OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ACH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '5e3461bc53b65b311af7dd21f335cd7c',
    PRODUCT: 'PLA OHLCV Hourly',
    PACKAGE: 'OHLCV Hourly',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '84488a4f42d8c8a48f34580c4316ab88',
    PRODUCT: 'ACH OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ACH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '6c8497b700ff9a9d16b90482ea772541',
    PRODUCT: 'RAI OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '07e36385fe3e9a3bd1560f2b367bab4b',
    PRODUCT: 'PLA OHLCV Daily',
    PACKAGE: 'OHLCV Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-04',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '8483a645d560913c9b3aadd3daccbdd6',
    PRODUCT: 'ACH Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'ACH',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: 'a01ac32ae97c14dbcc2eb4c3778e58d0',
    PRODUCT: 'RAI Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'RAI',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-03',
  },
  {
    CATEGORY: 'Exchange Data',
    CATALOGUE_ID: '13117d94c86414b1a2a705e10630a0f1',
    PRODUCT: 'PLA Last Price Daily',
    PACKAGE: 'Last Price Daily',
    QUOTE: 'USD',
    SOURCE: 'Coinbase',
    BASE: 'PLA',
    COVERAGE_TO: '2022-06-01',
    PROVIDER: 'Coinbase',
    COVERAGE_FROM: '2021-08-04',
  },
];
