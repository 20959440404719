import uniqBy from 'lodash/uniqBy';

import { packages } from ':data-marketplace/data/packages';
import { products } from ':data-marketplace/data/products';
import { Category } from ':data-marketplace/types/Category';

// Get a unique list of categories
export const categories: Category[] = uniqBy(
  products.map((product) => ({
    id: product.categoryId,
    name: product.categoryName,
    summary: product.categorySummary,
    description: product.categoryDescription,
    priority: product.categoryPriority ?? 0,
    packages: packages.filter((productItem) => productItem.categoryId === product.categoryId),
  })),
  'id',
).sort((a, b) => a.priority - b.priority);

export const allCategories: Category = {
  id: 'all',
  name: 'All Categories',
  packages,
};

export const categoriesAndAll = [allCategories, ...categories];
