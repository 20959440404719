import { CategoryInfo } from ':data-marketplace/types/Category';

export const categoryInfo: Record<string, CategoryInfo> = {
  'exchange-data': {
    name: 'Coinbase Exchange Data',
    summary: 'Comprehensive historical data from top crypto exchanges.',
    description:
      'All the Coinbase Exchange historical data and more available in one place. Explore individual data products and aggregated packages.',
    priority: 0,
  },
  'blockchain-data': {
    name: 'Blockchain Data',
    summary: 'On-chain datasets designed by Coinbase blockchain experts',
    description:
      'On-chain datasets built by our team of blockchain experts here at Coinbase. Explore individual data products and aggregated packages.',
    priority: 1,
  },
};
