import uniqBy from 'lodash/uniqBy';

import { products } from ':data-marketplace/data/products';
import { packageInfo } from ':data-marketplace/messages/packages';
import { Package } from ':data-marketplace/types/Package';

// Get a unique list of package names
const availablePackages: Package[] = uniqBy(
  products.map((product) => ({
    id: product.packageId,
    categoryId: product.categoryId,
    name: product.packageName,
    summary: product.packageSummary,
    description: product.packageDescription,
    priority: product.packagePriority ?? 0,
    isComingSoon: false,
  })),
  'id',
).sort((a, b) => a.priority - b.priority);

const upcomingPackages: Package[] = [
  {
    id: 'quote-data',
    categoryId: 'exchange-data',
    name: packageInfo['quote-data'].name,
    summary: packageInfo['quote-data'].summary,
    description: packageInfo['quote-data'].description,
    priority: packageInfo['quote-data'].priority ?? 0,
    isComingSoon: true,
  },
  {
    id: 'l2-snapshot',
    categoryId: 'exchange-data',
    name: packageInfo['l2-snapshot'].name,
    summary: packageInfo['l2-snapshot'].summary,
    description: packageInfo['l2-snapshot'].description,
    priority: packageInfo['l2-snapshot'].priority,
    isComingSoon: true,
  },
  {
    id: 'l2-tick-level',
    categoryId: 'exchange-data',
    name: packageInfo['l2-tick-level'].name,
    summary: packageInfo['l2-tick-level'].summary,
    description: packageInfo['l2-tick-level'].description,
    priority: packageInfo['l2-tick-level'].priority,
    isComingSoon: true,
  },
  {
    id: 'l3-tick-level',
    categoryId: 'exchange-data',
    name: packageInfo['l3-tick-level'].name,
    summary: packageInfo['l3-tick-level'].summary,
    description: packageInfo['l3-tick-level'].description,
    priority: packageInfo['l3-tick-level'].priority,
    isComingSoon: true,
  },
  {
    id: 'fixing-prices-daily',
    categoryId: 'exchange-data',
    name: packageInfo['fixing-prices-daily'].name,
    summary: packageInfo['fixing-prices-daily'].summary,
    description: packageInfo['fixing-prices-daily'].description,
    priority: packageInfo['fixing-prices-daily'].priority,
    isComingSoon: true,
  },
].sort((a, b) => a.priority - b.priority);

export const packages: Package[] = [...availablePackages, ...upcomingPackages];

export const allPackages: Package = {
  id: 'all',
  name: 'All Packages',
  // Had to write this manually to prevent circular deps
  categoryId: 'all',
  isComingSoon: false,
  priority: 0,
};

export const packagesAndAll = [allPackages, ...packages];
